import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import {
  Button,
  Col,
  Container,
  Row,
  FormGroup,
  FormLabel, 
  FormControl,
  InputGroup,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { LoginUser, fetchUserData } from "../../store/slice/authSlice";
import "./login.css";
import eye from "../../assets/images/eye.svg";
import eyeslash from "../../assets/images/eyeslash.svg"; 

// Define session timeout durations in ms
const INACTIVE_SESSION_TIMEOUT = 30 * 60 * 1000; // 30 mins
const ACTIVE_SESSION_TIMEOUT = 12 * 60 * 60 * 1000; // 12 hrs

const Login = () => {
  const [message, setMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isActiveSession, setIsActiveSession] = useState(false);

  const loginSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    email: Yup.string().email("Invalid email").required("Required"),
  });

  useEffect(() => {
    // Clear authentication token and session timeout on component mount
    sessionStorage.removeItem("authToken");

    // Set up session timeout
    let timeoutId;

    const resetTimeout = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(
        () => {
          // Perform logout or session expiration actions here
          handleLogout(); // Example function to logout the user
        },
        isActiveSession ? ACTIVE_SESSION_TIMEOUT : INACTIVE_SESSION_TIMEOUT
      );
    };

    const handleUserActivity = () => {
      resetTimeout();
      setIsActiveSession(true);
      sessionStorage.setItem("isActiveSession", "true");
    };

    window.addEventListener("mousemove", handleUserActivity);
    window.addEventListener("keypress", handleUserActivity);

    resetTimeout(); 
    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener("mousemove", handleUserActivity);
      window.removeEventListener("keypress", handleUserActivity);
    };
  }, [isActiveSession]);

  const handleLogout = () => {
    // Clear session data and redirect to login page
    sessionStorage.removeItem("authToken");
    sessionStorage.removeItem("isActiveSession");
    navigate("/login");
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const response = await dispatch(LoginUser(values)); 
      const { payload: {token, email, status}} = response;

      if(status===500){
        setMessage("Invalid credentials");
        alert('Invalid credentials');
      }
      if(status===404){
        setMessage("User not found");
        alert('User not found');
      }
     
      await dispatch(fetchUserData());

      // Store authentication token in session storage
      sessionStorage.setItem("authToken", token);
      setSubmitting(false); 
      navigate("/dashboard");
    } catch (error) {
      console.error("Login failed:", error);
      setSubmitting(false);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(prevState => !prevState);
  };

  return (
    <Formik
      initialValues={{ email: "", password: "" }}
      validationSchema={loginSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <Container fluid>
          <Row style={{ display: "flex", justifyContent: "space-between" }}>
            <Col md={6} className="login__form--container">
              <Form
              className="login_form"
                style={{
                  width: "80%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <h1 className="mb-5">Login to your account</h1>
                <FormGroup>
                  <FormLabel id="subhead">Email Address:</FormLabel>
                  <Field
                    type="email"
                    name="email"
                    placeholder="Enter email"
                    as={FormControl}
                  />
                </FormGroup>

                <FormGroup className="mb-4">
                  <FormLabel id="subhead">Password:</FormLabel>
                  <InputGroup>
                    <Field
                      type={showPassword ? "text" : "password"}
                      name="password"
                      placeholder="Enter Password"
                      as={FormControl}
                    />
                    <InputGroup.Text onClick={togglePasswordVisibility} style={{ cursor: "pointer", marginBottom: "16px" }}>
                      <img
                        src={showPassword ? eyeslash : eye}
                        alt="Toggle visibility"
                        height={16}
                        width={16}
                      />
                    </InputGroup.Text>
                  </InputGroup>
                </FormGroup>
                {message && (
                  <div>{message}</div>
                )}

                <Button type="submit" disabled={isSubmitting}>
                  {isSubmitting ? "Logging in..." : "Login"}
                </Button>

                <p className="pt-3 text-center">
                  <Link to="/forgetpassword">Forgot Password?</Link>
                </p>

                <p className="text-center">
                  Don't have an account?{" "}
                  <Link to="/signup">Create account</Link>{" "}
                </p>
                <Link to="/">Back to main</Link>
              </Form>
            </Col>
            <Col md={6} className="login__image--container"></Col>
          </Row>
        </Container>
      )}
    </Formik>
  );
};

export default Login;
