import React, { useState } from 'react';
import axios from 'axios';
import './pass.css';
import { useLocation, useHistory, useNavigate } from 'react-router-dom';

const authAPIUrl = process.env.REACT_APP_AUTH_API_URL;

const ResetPassword = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false); // State for showing the popup
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const token = query.get('token');
  const navigate = useNavigate();

  const validatePassword = (password) => {
    if (password.length < 8) {
      return 'Password must be at least 8 characters long.';
    }
    if (!/[A-Z]/.test(password)) {
      return 'Password must contain at least one uppercase letter.';
    }
    if (!/\d/.test(password)) {
      return 'Password must contain at least one numeric digit.';
    }
    if (!/[@$!%*?_&]/.test(password)) {
      return 'Password must contain at least one special character.';
    }

    return '';
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);

    const error = validatePassword(newPassword);
    setMessage(error);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setMessage('Passwords do not match.');
      return;
    }

    if (message) {
      setMessage('Please ensure the password meets all the requirements.');
      return;
    }

    setLoading(true);
    try {
      const response = await axios.post(`${authAPIUrl}/auth/reset-password`, { token, password });
      setMessage(response.data);
      setShowPopup(true); // Show the popup after successful password reset
    } catch (error) {
      setMessage('Failed to reset password. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleLoginRedirect = () => {
    navigate('/login'); 
  };

  return (
    <div>
      <h2>Reset Password</h2>
      <form id="fom" onSubmit={handleSubmit}>
        <div>
        <label>
          New Password:
          <input 
            type="password" 
            id='op'
            style={{width: "70%"}}
            value={password} 
            onChange={handlePasswordChange} 
            required 
          />
        </label>
        </div>
        <div>
        <label>
          Confirm Password:
          <input 
            type="password" 
            id='op'
            style={{width: "70%"}}
            value={confirmPassword} 
            onChange={(e) => setConfirmPassword(e.target.value)} 
            required 
          />
        </label>
        </div>
        <button type="submit" disabled={loading || message} className='reb'>
          {loading ? 'Resetting...' : 'Reset Password'}
        </button>
      </form>
      {message && <p style={{ color: 'red', margin: "10px" }}><i>{message}</i></p>}

      {showPopup && (
        <div style={popupStyles}>
          <div style={popupContentStyles}>
            <p>Password is Updated!</p>
            <button style={popupbutton} onClick={handleLoginRedirect}>Login</button>
          </div>
        </div>
      )}
    </div>
  );
};

// Styles for the popup
const popupStyles = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const popupContentStyles = {
  backgroundColor: 'white',
  padding: '20px',
  borderRadius: '10px',
  textAlign: 'center',
};

const popupbutton = {
  padding: '6px 18px',
  border: 'none',
  borderRadius: '5px',
  cursor: 'pointer',
  backgroundColor: '#9574ff',
  color: 'white',
  fontSize: '16px',
};


export default ResetPassword;
