import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./pricingfooter.css";

const PricingFooter = () => {
  const navigate = useNavigate();
  const [isYearly, setIsYearly] = useState(true);

  const handleToggle = () => {
    setIsYearly(!isYearly);
  };

  const prices = {
    yearly: { Basic: "₹1999/year", Pro: "₹3499/year"  },
    monthly: { Basic: "₹199/month", Pro: "₹349/month" },
  };

  const handleNavigation = (planType) => {
    const planDetails = {
      type: planType,
      isYearly,
      amount: isYearly ? prices.yearly[planType] : prices.monthly[planType],
    };

    navigate("/billing", { state: planDetails });
  };

  return (
    <div>
      <section className="pricingbox">
        <h6 className="compare">
          Compare <span id="plan">Plans</span>
        </h6>
        <table
          className="table table-bordered container-fluid my-4"
          id="main_table"
        >
          <thead>
            <tr className="sticky">
              <th scope="col" className="col-6" id="choose">
                Choose Your Plans
                <br />
                <div className="toggle-container">
                  <div className="toggle-button" onClick={handleToggle}>
                    <div
                      className="toggle-background"
                      style={{
                        transform: isYearly
                          ? "translateX(0)"
                          : "translateX(100%)",
                      }}
                    />
                    <div
                      className={`toggle-option ${isYearly ? "selected" : ""}`}
                    >
                      Yearly
                    </div>
                    <div
                      className={`toggle-option ${!isYearly ? "selected" : ""}`}
                    >
                      Monthly
                    </div>
                  </div>
                </div>
                {/* <button
                  type="submit"
                  id="yearly"
                  onClick={handleToggle}
                  className="btn btn-primary rounded-pill "
                >
                  {isYearly ? "Yearly" : "Monthly"}
                </button> */}
              </th>
              <th scope="col" className="col-3 content-pricing">
                <span className="text_basic">Basic</span>
                <br />
                <span>
                  {isYearly ? prices.yearly.Basic : prices.monthly.Basic}
                </span>
                <br />
                <button
                  type="submit"
                  className="learn"
                  onClick={() => handleNavigation("Basic")}
                >
                  Subscribe
                </button>
              </th>
              <th scope="col" className="col-3 content-pricing">
                <span className="text_basic">Pro</span>
                <br />
                <span>{isYearly ? prices.yearly.Pro : prices.monthly.Pro}</span>
                <br />
                <button
                  type="submit"
                  className="learn"
                  onClick={() => handleNavigation("Pro")}
                >
                  Subscribe
                </button>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{ backgroundColor: "#f4f4f4" }}>
                <div className="table_text">Trade Logging and Tracking</div>
                <div className="table_text">Time-Specific Analysis</div>
                <div className="table_text">Equity Account Growth Curve</div>
              </td>
              <td>
                <div className="table_text">
                  <i className="bi bi-check-lg" />
                </div>
                <div className="table_text">
                  <i className="bi bi-check-lg" />
                </div>
                <div className="table_text">
                  <i className="bi bi-check-lg" />
                </div>
              </td>
              <td>
                <div className="table_text">
                  <i className="bi bi-check-lg" />
                </div>
                <div className="table_text">
                  <i className="bi bi-check-lg" />
                </div>
                <div className="table_text">
                  <i className="bi bi-check-lg" />
                </div>
              </td>
            </tr>

            <tr>
              <td
                className="bg"
                style={{
                  backgroundColor: "#f4f4f4",
                }}
              >
                <div className="table_text">Max Drawdown (DD) Value</div>
                <div className="table_text">Insights Based on Performance</div>
                <div className="table_text">4 Types of Calculators</div>
              </td>
              <td className="icon-center">
                <div className="table_text">-</div>
                <div className="table_text">-</div>
                <div className="table_text">
                  <i className="bi bi-check-lg" />
                </div>
              </td>
              <td className="icon-center">
                <div className="table_text">
                  <i className="bi bi-check-lg" />
                </div>
                <div className="table_text">
                  <i className="bi bi-check-lg" />
                </div>
                <div className="table_text">
                  <i className="bi bi-check-lg" />
                </div>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  backgroundColor: "#f4f4f4",
                }}
              >
                <div className="table_text">Daily Questionnaire</div>
                <div className="table_text">Exporting Trade Logs</div>
                <div className="table_text">Adding customised Trade log columns</div>
              </td>
              <td>
                <div className="table_text">-</div>
                <div className="table_text">-</div>
                <div className="table_text">-</div>
              </td>
              <td>
                <div className="table_text">
                  <i className="bi bi-check-lg" />
                </div>
                <div className="table_text">
                  <i className="bi bi-check-lg" />
                </div>
                <div className="table_text">
                  <i className="bi bi-check-lg" />
                </div>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  backgroundColor: "#f4f4f4",
                }}
              >
                <div className="table_text">Trading Accounts & Strategies</div>
                <div className="table_text">Import Past Trade Log</div>
                <div className="table_text">Future Projection Graph</div>
              </td>
              <td>
                <div className="table_text">
                  <i className="bi bi-check-lg" />
                </div>
                <div className="table_text">-</div>
                <div className="table_text">-</div>
              </td>
              <td>
                <div className="table_text">
                  <i className="bi bi-check-lg" />
                </div>
                <div className="table_text">
                  <i className="bi bi-check-lg" />
                </div>
                <div className="table_text">
                  <i className="bi bi-check-lg" />
                </div>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  backgroundColor: "#f4f4f4",
                }}
              >
                <div className="table_text">Advanced Graphical Analysis</div>
                <div className="table_text">Graphical Representation of Factors</div>
                <div className="table_text">Monthly Q&A Forms</div>
              </td>
              <td>
                <div className="table_text">-</div>
                <div className="table_text">
                  <i className="bi bi-check-lg safe" />
                </div>
                <div className="table_text">-</div>
              </td>
              <td>
                <div className="table_text">
                  <i className="bi bi-check-lg" />
                </div>
                <div className="table_text">
                  <i className="bi bi-check-lg" />
                </div>
                <div className="table_text">
                  <i className="bi bi-check-lg" />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </section>
    </div>
  );
};

export default PricingFooter;
