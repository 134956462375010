import React from 'react'
import './Howitswork.css';
import yt from './yt.png';

const Howitswork = () => {
  return (
    <div>
        {/*Review Section*/}
        <section className="reviews-responsive">
            <h1 className="term"><span id="startclr">Start Trading</span> on Your Terms</h1>
            <div className="container-fluid">
            <div className="row" style={{marginBottom: "35px"}}>
                <div className="col-md-3">
                <div className="row">
                    <div className="col-md-5 big-circle" style={{cursor:"pointer"}}>
                        <div className="circle-res text-center">
                            <p style={{position: 'absolute',marginTop: "-70px", paddingBottom: '5%', fontSize: 18}}>Open <br /> Your Account
                            </p>
                            <p style={{position: 'absolute', paddingTop: '13%', fontSize: 15, color: 'rgba(110, 110, 110, 1)'}}>
                            Get started by <br/>setting up your account <br/>quickly and easily</p>
                            <div className="circle_small2-res">
                                <h2 className="step">Step 01</h2>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-2" />
                    <div className="col-md-5 small-circle-res">
                        <div className="circle_small-res"><i className="bi bi-arrow-down-short" /></div>
                    </div>
                </div>
                </div>
                <div className="col-md-3" style={{cursor:"pointer"}}>
                <div className="row">
                    <div className="col-md-5 big-circle">
                    <div className="circle-res text-center">
                        {/* <p style={{position: 'absolute', paddingBottom: '5%', fontSize: 18}}>Select a <br /> currency
                        pair</p> */}
                        <p style={{ position: 'absolute',marginTop: "-70px", paddingBottom: '5%', fontSize: 18 }}>Log Your Trades</p>
                        <p style={{position: 'absolute', paddingTop: '13%', fontSize: 15, color: 'rgba(110, 110, 110, 1)'}}>
                        Keep track of all <br/>your trades with ease</p>
                        <div className="circle_small2-res">
                        <h2 className="step">Step 02</h2>
                        </div>
                    </div>
                    </div>
                    <div className="col-md-2" />
                    <div className="col-md-5 small-circle-res">
                    <div className="circle_small-res"><i className="bi bi-arrow-down-short" /></div>
                    </div>
                </div>
                </div>
                <div className="col-md-3" style={{cursor:"pointer"}}>
                <div className="row">
                    <div className="col-md-5 big-circle">
                    <div className="circle-res text-center">
                        <p style={{position: 'absolute',marginTop: "-70px", paddingBottom: '5%', fontSize: 18, color: 'rgba(27, 30, 49, 1)'}}>
                        Analyze with <br/>Personalized Analytics</p>
                        <p style={{position: 'absolute', paddingTop: '13%', fontSize: 15, color: 'rgba(110, 110, 110, 1)'}}>
                        Get personalized insights <br/>into your trading patterns</p>
                        <div className="circle_small2-res">
                        <h2 className="step">Step 03</h2>
                        </div>
                    </div>
                    </div>
                    <div className="col-md-2" />
                    <div className="col-md-5 small-circle-res">
                    <div className="circle_small-res"><i className="bi bi-arrow-down-short" /></div>
                    </div>
                </div>
                </div>
                <div className="col-md-3 big-circle" style={{cursor:"pointer"}}>
                <div className="circle-res text-center">
                    <p style={{position: 'absolute', marginTop: "-70px", paddingBottom: '5%', fontSize: 18}}>Master Your Trading</p>
                    <p style={{position: 'absolute', paddingTop: '13%', fontSize: 15, color: 'rgba(110, 110, 110, 1)'}}>
                    Use the insights gained <br/>to become a better trader</p>
                    <div className="circle_small2-res">
                    <h2 className="step">Step 04</h2>
                    </div>
                </div>
                </div>
            </div>
        </div>
  </section>
























  <section className="reviews">
            <h1 className="term"><span id="startclr">Start Trading</span> on Your Terms</h1>
            <div className="container-fluid" id="cff">
            <div className="row" style={{marginBottom: "35px"}}>
                <div className="col-md-3">
                <div className="row">
                    <div className="col-md-5" style={{cursor:"pointer"}}>
                    <div className="circle text-center">
                        <p style={{position: 'absolute', paddingBottom: '5%', fontSize: 18}}>Open <br /> Your Account
                        </p>
                        <p style={{position: 'absolute', paddingTop: '2%', fontSize: 15, color: 'rgba(110, 110, 110, 1)'}}>
                        Get started by setting up your <br/>account quickly and easily</p>
                        <div className="circle_small2">
                        <h2 className="step">Step 01</h2>
                        </div>
                    </div>
                    </div>
                    <div className="col-md-2" />
                    <div className="col-md-5">
                    <div className="circle_small"><i className="bi bi-arrow-right-short" /></div>
                    </div>
                </div>
                </div>
                <div className="col-md-3" style={{cursor:"pointer"}}>
                <div className="row">
                    <div className="col-md-5">
                    <div className="circle text-center">
                        <p style={{position: 'absolute', paddingBottom: '5%', fontSize: 18}}>Log Your Trades</p>
                        <p style={{position: 'absolute', paddingTop: '2%', fontSize: 15, color: 'rgba(110, 110, 110, 1)'}}>
                        Keep track of all <br/>your trades with ease</p>
                        <div className="circle_small2">
                        <h2 className="step">Step 02</h2>
                        </div>
                    </div>
                    </div>
                    <div className="col-md-2" />
                    <div className="col-md-5">
                    <div className="circle_small"><i className="bi bi-arrow-right-short" /></div>
                    </div>
                </div>
                </div>
                <div className="col-md-3" style={{cursor:"pointer"}}>
                <div className="row">
                    <div className="col-md-5">
                    <div className="circle text-center">
                        <p style={{position: 'absolute', paddingBottom: '5%', fontSize: 18, color: 'rgba(27, 30, 49, 1)'}}>
                        Analyze with <br/>Personalized Analytics</p>
                        <p style={{position: 'absolute', paddingTop: '2%', fontSize: 15, color: 'rgba(110, 110, 110, 1)'}}>
                        Get personalized insights <br/>into your trading patterns</p>
                        <div className="circle_small2">
                        <h2 className="step">Step 03</h2>
                        </div>
                    </div>
                    </div>
                    <div className="col-md-2" />
                    <div className="col-md-5">
                    <div className="circle_small"><i className="bi bi-arrow-right-short" /></div>
                    </div>
                </div>
                </div>
                <div className="col-md-3" style={{cursor:"pointer"}}>
                <div className="circle2 text-center">
                    <p style={{position: 'absolute', paddingBottom: '5%', fontSize: 18}}>Master Your Trading</p>
                    <p style={{position: 'absolute', paddingTop: '2%', fontSize: 15, color: 'rgba(110, 110, 110, 1)'}}>
                    Use the insights gained <br/>to become a better trader</p>
                    <div className="circle_small2">
                    <h2 className="step">Step 04</h2>
                    </div>
                </div>
                </div>
            </div>
        </div>
  </section>
    </div>
  )
}

export default Howitswork;
