import React, { useState } from "react";
import "./editProfile.css";
import { useDispatch, useSelector } from "react-redux";
import { deleteUser, updateUser } from "../../store/slice/userSlice";
import { fetchUserData } from "../../store/slice/authSlice";
import eye from "../../assets/images/eye.svg";
import eyeslash from "../../assets/images/eyeslash.svg";

const EditProfile = () => {
  const userinfo = useSelector((state) => state?.auth?.user);
  const [user, setUser] = useState(userinfo);
  const token = useSelector((state) => state.auth.token);
  const [inputValue, setInputValue] = useState("");
  const dispatch = useDispatch();
  const [confirmDelete, setConfirmDelete] = useState(null);
  const [isEditable, setIsEditable] = useState(false);
  const [hidden, setHidden] = useState({
    oldPassword: true,
    newPassword: true,
    confirmPassword: true,
  });
  const [formValues, setFormValues] = useState({
    firstName: user.first_name || "",
    lastName: user.last_name || "",
    email: user.email || "",
    mobile: user.mobile || "",
  });
  const [passwords, setPasswords] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = useState({
    newPassword: "",
    confirmPassword: "",
  });

  const handleEditClick = () => {
    if (isEditable) {
      // Reset passwords when starting to edit
      setPasswords({
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
      });
    }
    setIsEditable(!isEditable);
  };

  const handleCancelClick = () => {
    // Reset form values and passwords
    setFormValues({
      firstName: user.first_name,
      lastName: user.last_name,
      email: user.email,
      mobile: user.mobile,
    });
    setPasswords({
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    });
    setErrors({ newPassword: "", confirmPassword: "" });
    setIsEditable(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (["oldPassword", "newPassword", "confirmPassword"].includes(name)) {
      setPasswords((prevPasswords) => ({ ...prevPasswords, [name]: value }));
      if (name === "newPassword") {
        validateNewPassword(value);
      } else if (name === "confirmPassword") {
        validateConfirmPassword(value);
      }
    } else {
      setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
    }
  };

  const validateNewPassword = (value) => {
    let errorMessage = "";

    if (value.length < 8) {
      errorMessage = "*Password should be a minimum of 8 characters.";
    } else if (!/[A-Z]/.test(value)) {
      errorMessage = "*At least one uppercase letter is required.";
    } else if (!/\d/.test(value)) {
      errorMessage = "*At least one numeric digit is required.";
    } else if (!/[(@$!%*?&_)]/.test(value)) {
      errorMessage = "*At least one special character is required.";
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      newPassword: errorMessage,
    }));
  };

  const validateConfirmPassword = (value) => {
    let errorMessage = "";

    if (value && value !== passwords.newPassword) {
      errorMessage = "*Passwords do not match.";
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      confirmPassword: errorMessage,
    }));
  };

  const handleSaveClick = async (e) => {
    e.preventDefault();
    if (passwords.oldPassword !== "") {
      if (passwords.confirmPassword === "") {
        setErrors((prev) => ({
          ...prev,
          confirmPassword: "*Empty password is not allowed",
          newPassword: "*Empty password is not allowed",
        }));
        return;
      }
    }
    const updatedData = {
      first_name: formValues.firstName,
      last_name: formValues.lastName,
      email: formValues.email,
      mobile: formValues.mobile,
      old_password: passwords.oldPassword,
      password: passwords.confirmPassword,
    };

    if (!errors.newPassword && !errors.confirmPassword) {
      const payload = {
        values: updatedData,
        token: token,
      };
      try {
        const response = await dispatch(updateUser(payload));
        const { status } = response.payload;
        if (status === 500) { 
          alert("Invalid Old Password");
        } else {
          alert("User Details Updated");
          await dispatch(fetchUserData());
        }
        setIsEditable(false);
      } catch (error) {
        alert("Error. Not Saved.");
        console.log(error);
      }
    }
  };

  const toggleVisibility = (fieldName) => {
    setHidden((prevHidden) => ({
      ...prevHidden,
      [fieldName]: !prevHidden[fieldName],
    }));
  };

  const handleInputC = (e) =>{
    setInputValue(e.target.value);
  }

  const handleDeleteAccount = async() => {
    const response = await dispatch(deleteUser({id: user.id, token: token}));
    if(response.payload.status === 200){
      alert("User Account Deleted!");
      localStorage.clear();
      window.location.reload();
    } else {
      alert('Error. If you face any difficulty, reach our support team via email.')
    }
  }

  return (
    <>
      {confirmDelete && (
        <div className="overlay">
          <div className="popupp">
            <p>
              Account Deletion would completely remove your account and all your
              data associated with it, permanently!
            </p>
            <p>
              If you are sure about your account deletion, type{" "}
              <b>
                <i>delete</i>
              </b>{" "}
              below
            </p>
            <input className="inppp" type="text" placeholder="delete" value={inputValue} onChange={handleInputC} name="delete" />
            <div>
              <button className="confirm_button" disabled={inputValue !== "delete"} onClick={handleDeleteAccount}>Confirm</button>
            </div>
          </div>
        </div>
      )}

      {user && (
        <div className="container-fluid">
          <div className="row justify-content-between">
            <div className="col-4">
              <p>Personal Information</p>
            </div>
            <div className="col-2 m-2">
              <button
                type="button"
                onClick={handleEditClick}
                className="save_button"
              >
                Edit
              </button>
            </div>
          </div>
          <form>
            <div className="roww">
              <div className="form-rows">
                <label>First Name:</label>
                <input
                  className="inpp"
                  name="firstName"
                  value={formValues.firstName}
                  type="text"
                  disabled={!isEditable}
                  onChange={handleInputChange}
                />
              </div>
              <div className="form-rows">
                <label>Last Name:</label>
                <input
                  className="inpp"
                  name="lastName"
                  value={formValues.lastName}
                  type="text"
                  disabled={!isEditable}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="roww">
              <div className="form-rows">
                <label>Email:</label>
                <input
                  className="inpp"
                  name="email"
                  value={formValues.email}
                  type="text"
                  disabled={true}
                  onChange={handleInputChange}
                />
              </div>
              <div className="form-rows">
                <label>Mobile:</label>
                <input
                  className="inpp"
                  name="mobile"
                  value={formValues.mobile}
                  type="text"
                  disabled={!isEditable}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            {!isEditable && (
              <div id="msg">
                <p>
                  *Click on <b>Edit</b> to edit your details and password!
                </p>
              </div>
            )}
            {isEditable && (
              <>
                <div className="roww">
                  <div className="form-rows">
                    <label>Old Password:</label>
                    <div className="pw">
                      <input
                        className="inpp2"
                        name="oldPassword"
                        type={hidden.oldPassword ? "password" : "text"}
                        placeholder="Old Password"
                        value={passwords.oldPassword}
                        onChange={handleInputChange}
                      />
                      <div
                        className="eye_icon"
                        onClick={() => toggleVisibility("oldPassword")}
                      >
                        <img
                          src={hidden.oldPassword ? eye : eyeslash}
                          height={19}
                          width={19}
                          alt="toggle password visibility"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-rows">
                    <label>New Password:</label>
                    <div className="pw">
                      <input
                        className="inpp2"
                        name="newPassword"
                        type={hidden.newPassword ? "password" : "text"}
                        placeholder="New Password"
                        value={passwords.newPassword}
                        onChange={handleInputChange}
                        disabled={!passwords.oldPassword}
                      />
                      <div
                        className="eye_icon"
                        onClick={() => toggleVisibility("newPassword")}
                      >
                        <img
                          src={hidden.newPassword ? eye : eyeslash}
                          height={19}
                          width={19}
                          alt="prev"
                        />
                      </div>
                    </div>
                    {errors.newPassword && (
                      <p className="error-message">{errors.newPassword}</p>
                    )}
                    {!passwords.oldPassword && (
                      <p style={{ fontSize: "12px" }}>
                        <i>Old password required inorder to update password</i>
                      </p>
                    )}
                  </div>
                  <div className="form-rows">
                    <label>Confirm Password:</label>
                    <div className="pw">
                      <input
                        className="inpp2"
                        name="confirmPassword"
                        type={hidden.confirmPassword ? "password" : "text"}
                        placeholder="Confirm Password"
                        value={passwords.confirmPassword}
                        onChange={handleInputChange}
                        disabled={!passwords.oldPassword}
                      />
                      <div
                        className="eye_icon"
                        onClick={() => toggleVisibility("confirmPassword")}
                      >
                        <img
                          src={hidden.confirmPassword ? eye : eyeslash}
                          height={19}
                          width={19}
                          alt="prev"
                        />
                      </div>
                    </div>
                    {errors.confirmPassword && (
                      <p className="error-message">{errors.confirmPassword}</p>
                    )}
                  </div>
                </div>
                <div className="rowwb">
                  <div>
                    <button
                      type="button"
                      onClick={handleCancelClick}
                      className="cancel_button"
                    >
                      Cancel
                    </button>
                  </div>
                  <div>
                    <button
                      type="submit"
                      onClick={handleSaveClick}
                      className="save_button"
                      disabled={errors.newPassword || errors.confirmPassword}
                    >
                      Save
                    </button>
                  </div>
                </div>
                <div className="roww">
                  <div style={{marginTop: "50%"}}>
                    <button
                      type="button"
                      className="delete_button"
                      onClick={() => setConfirmDelete(true)}
                    >
                      Delete Account
                    </button>
                  </div>
                </div>
              </>
            )}
          </form>
        </div>
      )}
    </>
  );
};

export default EditProfile;
