import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "./signup.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addUser } from "../../store/slice/userSlice";
import axios from "axios";

const Signup = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const users = useSelector((state) => state.users.data);
  const [initialValues, setInitialValues] = useState({
    first_name: "",
    last_name: "",
    email: "",
    mobile: "",
    password: "",
    confirm_password: "",  // Added confirm_password
    role: "user",
  });

  useEffect(() => {
    if (params.id) {
      const user = users.find((user) => user.id === params.id);
      if (user) {
        setInitialValues(user);
      }
    }
  }, [params.id, users]);

  const userSchema = Yup.object().shape({
    first_name: Yup.string().required("Enter your first name"),
    last_name: Yup.string().required("Enter your last name"),
    email: Yup.string()
      .email("Please enter a valid email")
      .required("Email is required"),
    mobile: Yup.string()
      .required("Enter your phone number")
      .matches(
        /^((\+[1-9]{1,4}[ \-]*)|(\([0-9]{2,3}\)[ \-]*)|([0-9]{2,4})[ \-]*)*?[0-9]{3,4}?[ \-]*[0-9]{3,4}?$/,
        "Phone number is not valid"
      ),
    password: Yup.string()
      .required("Enter your password")
      .min(8, "Password must be at least 8 characters long")
      .matches(/[A-Z]/, "Password must have at least one uppercase letter")
      .matches(/[0-9]/, "Password must have at least one numeric digit")
      .matches(
        /[@$!%*?&#_()]/,
        "Password must have at least one special character"
      ),
    confirm_password: Yup.string()
      .oneOf([Yup.ref('password'), null], "Passwords must match")
      .required("Confirm your password"),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const {confirm_password, ...otherval} = values;
      const response = await dispatch(addUser(otherval)).unwrap();

      const { auth_token } = response;

      // Handle token and user data
      alert("User created. Going back to the Login Page...");

      sessionStorage.setItem("authToken", auth_token);

      navigate('/login');

      // Proceed with navigation or other actions
    } catch (error) {
      setSubmitting(false);
      console.error("Error creating user:", error);
      if (error.message === "Email already in use.") alert(error.message);
    }
  };

  return (
    <div className="container-fluid py-5" style={{height: "100%"}}>
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="card shadow-lg border-0 rounded-lg">
            <div className="card-header">
              <h3 className="text-center font-weight-light my-4">
                Sign Up
              </h3>
            </div>
            <Formik
              initialValues={initialValues}
              validationSchema={userSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting }) => (
                <Form className="sign_form">
                  <div className="fir_row">
                    <div className="mb-2">
                      <label htmlFor="first_name" className="form-label">
                        First Name
                      </label>
                      <Field
                        type="text"
                        name="first_name"
                        placeholder="First Name"
                        id="first_name"
                        className="form-control"
                      />
                      <ErrorMessage
                        name="first_name"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <div className="mb-2">
                      <label htmlFor="last_name" className="form-label">
                        Last Name
                      </label>
                      <Field
                        type="text"
                        name="last_name"
                        placeholder="Last Name"
                        id="last_name"
                        className="form-control"
                      />
                      <ErrorMessage
                        name="last_name"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                  </div>
                  <div className="fir_row">
                    <div className="mb-2">
                      <label htmlFor="email" className="form-label">
                        Email
                      </label>
                      <Field
                        type="email"
                        name="email"
                        placeholder="Email"
                        id="email"
                        className="form-control"
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <div className="mb-2">
                      <label htmlFor="mobile" className="form-label">
                        Mobile
                      </label>
                      <Field
                        type="text"
                        name="mobile"
                        placeholder="Mobile"
                        id="mobile"
                        className="form-control"
                      />
                      <ErrorMessage
                        name="mobile"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                  </div>
                  <div className="mx-auto" style={{width: "35%", textAlign: "left"}}>
                    <div>
                      <label htmlFor="password" className="form-label" style={{fontWeight: "500"}}>
                        Password
                      </label>
                      <Field
                        type="password"
                        name="password"
                        placeholder="Password"
                        id="password"
                        className="form-control"
                      />
                      <ErrorMessage
                        name="password"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <div>
                      <label htmlFor="confirm_password" className="form-label" style={{fontWeight: "500"}}>
                        Confirm Password
                      </label>
                      <Field
                        type="password"
                        name="confirm_password"
                        placeholder="Confirm Password"
                        id="confirm_password"
                        className="form-control"
                      />
                      <ErrorMessage
                        name="confirm_password"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                  </div>
                  <div className="lastt">
                    <div className="mx-auto" style={{width: "30%", marginTop: "1rem", marginBottom: "1rem"}}>
                      <button
                        type="submit"
                        className="btn btn-primary btn-block bnn"
                        disabled={isSubmitting}
                      >
                        {params?.id ? "Update" : "Add"}
                      </button>
                    </div>
                    <div className="my-3">
                      <Link to="/login">Back to Login</Link>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
