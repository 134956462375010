import React, { useState } from 'react';
import './FAQ.css';

const faqData = [
  {
    question: "How do I log a new trade in the application?",
    answer: "To log a new trade, go to the \"Trade Log\" section of the website or click on \"New Trade\" button on top. Fill in the required details such as stock symbol, buy/sell, quantity, price, date of trade, and any other relevant information. Once done, click \"Save\" to log your trade.",
    img: "img/1.svg",
    answeredImage: "img/01.png"
  },
  {
    question: "Can I track my portfolio performance over time?",
    answer: "Yes, the application allows you to track your portfolio performance over time. In the \"Dashboard\" section, you can view detailed analytics and charts that show the growth or decline of your portfolio, along with other performance metrics.",
    img: "img/002.png",
    answeredImage: "img/2.svg"
  },
  {
    question: "Can I export my trade data for offline analysis?",
    answer: "Yes, you can export your trade data to Excel format for offline analysis. Go to the \"Trade Log\" section, click on \"Export\". You can then analyze your data using external tools like Excel or Google Sheets.",
    img: "img/003.png",
    answeredImage: "img/3.svg"
  },
  {
    question: "Is there a way to link multiple trading accounts?",
    answer: "Yes, you can link multiple trading accounts from different brokers to the application. Go to the \"Trading Accounts\" section, click on \"Add new Trading Account\" and follow the prompts to store all trading account details. This allows you to manage all your trades in one place by categorizing trades based on particular trading account.",
    img: "img/004.png",
    answeredImage: "img/4.svg"
  }
];

const FAQ = () => {
  const [visibleAnswers, setVisibleAnswers] = useState({ 0: true });

  const toggleAnswer = (index) => {
    setVisibleAnswers(prevState => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };

  return (
    <section className="container5 mt-5">
      <h3 className="find">Find <span id="ans">Answers</span> to Common<br />Questions</h3>
      <div className="faq">
        {faqData.map((faq, index) => (
          <div key={index} className={visibleAnswers[index] ? "ques" : "ques2"}>
            <div className="question-content">
              <img src={visibleAnswers[index] ? faq.answeredImage : faq.img} alt={`Question ${index + 1}`} />
              <div className="question-text">
                <span id={visibleAnswers[index] ? "how" : "how2"}>
                  {faq.question}
                </span>
                {visibleAnswers[index] && (
                  <h6 id={visibleAnswers[index] ? "mole" : "mole2"}>{faq.answer}</h6>
                )}
              </div>
              <span
                id={visibleAnswers[index] ? "minus" : "plus"}
                onClick={() => toggleAnswer(index)}
                style={visibleAnswers[index] ? { marginLeft: '10px' } : {}}
              >
                <img src={visibleAnswers[index] ? "img/cross.svg" : "img/plus.png"} alt="Toggle Answer" style={{ cursor: "pointer" }} />
              </span>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default FAQ;
