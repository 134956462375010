import React, { useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import ResultBox from "../resultBox";

const StockAverage = () => {
  const [stocks, setStocks] = useState([
    { quantity: "", entryPrice: "" }
  ]); // Array to store stock details
  const [result, setResult] = useState({
    "Total Quantity": 0,
    "Average Price": 0,
    "Total Amount": 0,
  });

  const handleAddClick = () => {
    if (stocks.length < 10) {
      setStocks([...stocks, { quantity: "", entryPrice: "" }]); // Add new empty input
    }
  };

  const handleInputChange = (index, field, value) => {
    const newStocks = [...stocks];
    newStocks[index][field] = value;
    setStocks(newStocks);
  };

  const handleCalculateClick = () => {
    let totalQuantity = 0;
    let totalAmount = 0;

    // Iterate through all the stocks and calculate total quantity and total amount
    stocks.forEach((stock) => {
      totalQuantity += Number(stock.quantity);
      totalAmount += Number(stock.quantity) * Number(stock.entryPrice);
    });

    // Calculate average price considering all stocks
    const averagePrice = totalAmount / totalQuantity;

    setResult({
      "Total Quantity": totalQuantity,
      "Average Price": isNaN(averagePrice) ? 0 : averagePrice.toFixed(2), // Handle division by zero
      "Total Amount": totalAmount.toFixed(2),
    });
  };

  const handleResetClick = () => {
    setStocks([{ quantity: "", entryPrice: "" }]); // Reset to initial state
    setResult({
      "Total Quantity": 0,
      "Average Price": 0,
      "Total Amount": 0,
    });
  };

  return (
    <Container>
      <Row>
        <Col sm={8} xs={12} className="calculatorArea">
          <div className="calculatorHeading">
            <p>You can calculate your Stock Average</p>
          </div>
          <div className="inputFields stock-average" id="one">
            {stocks.map((stock, index) => (
              <div key={index} className="individual-stocks">
                <p className="fieldsLabel">Quantity</p>
                <input
                  type="text"
                  className="customFieldInput"
                  placeholder="00"
                  value={stock.quantity}
                  onChange={(e) =>
                    handleInputChange(index, "quantity", e.target.value)
                  }
                />
                <p className="fieldsLabel">Entry Price</p>
                <input
                  type="text"
                  className="customFieldInput"
                  placeholder="Rs.0000"
                  value={stock.entryPrice}
                  onChange={(e) =>
                    handleInputChange(index, "entryPrice", e.target.value)
                  }
                />
              </div>
            ))}
            <Button
              variant="outline-primary"
              style={{ margin: " 0px 300px", width: "20%" }} 
              onClick={handleAddClick}
            >
              Add
            </Button>
          </div>
          <div className="buttons-area">
            <Button
              variant="primary"
              className="calculate-button"
              onClick={handleResetClick}
            >
              Reset
            </Button>
            <Button
              variant="primary"
              className="calculate-button"
              onClick={handleCalculateClick}
            >
              Calculate
            </Button>
          </div>
        </Col>
        <Col sm={4} xs={12} className="Result">
          <ResultBox result={result} />
        </Col>
      </Row>
    </Container>
  );
};

export default StockAverage;
