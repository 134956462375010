
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  tradingAccountDelete,
} from "../../store/slice/tradingAccountsSlice"; 

const TradeAccountList = ({ list, setFormStatus, onEdit }) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);

  const handleDelete = async (accountId) => {
    const res = await dispatch(
      tradingAccountDelete({
        accountId: accountId,
        token: token, 
      })
    );
    const msg = res.payload.data;
    if(msg.status === 200){
      alert("Trading Account Deleted");
    }
    if(msg.status === 501){
      alert("Trading Account is in use");
    }
    if(msg.status === 500){
      alert("Trading Account not found");
    }
  };

  const handleEdit = (account) => {
    onEdit(account); // Use the passed onEdit function
  };

  return (
    <div className="list-head">
      {list.map((el) => (
        <div key={el?.account_Id} className="account-card">
          <div className="account-info">
            <h3>{el?.trading_account}</h3>
            <p>{el?.account_name}</p>
            <p>{el?.account_email}</p>
            <p>{el?.account_mobile}</p>
            <p>{el?.purpose}</p>
          </div>
          <button className="ed-del-btn" onClick={() => handleEdit(el)}>
            Edit
          </button>
          {"  "}
          <button
            className="ed-del-btn"
            onClick={() => handleDelete(el?.id)}
          >
            Delete
          </button>
        </div>
      ))}
      <div className="nnnn" id="sepa">
        <button className="acc-btn"onClick={() => setFormStatus("add")}>+ Add New Trading Account</button>
      </div>
    </div>
  );
};

export default TradeAccountList;
