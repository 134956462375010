import React, { useState } from 'react';
import './Testimonials.css';
import starImage from './start_img.png'

const testimonialsData = [
  {
    name: "Rohit Verma",
    position: "Financial Analyst",
    review: "This platform has been a game-changer for tracking my trades. The insights into my performance have helped me refine my strategies. Highly recommend for serious traders!",
    img: "img/Ellipse 20.png",
    stars: Array(4).fill(starImage)
  },

  {
    name: "Priya Sharma",
    position: "Equity Researcher",
    review: "I love how easy it is to log my daily trades and see my progress over time. The profit and loss analysis is especially helpful in understanding my trading patterns.",
    img: "img/Ellipse 20.png",
    stars: Array(5).fill(starImage)
  },
  {
    name: "Vikram Patel",
    position: "Portfolio Manager",
    review: "My Trade Journal has simplified my trading routine. The ability to forecast and analyze my trades has boosted my confidence and decision-making. Excited for future updates!",
    img: "img/Ellipse 20.png",
    stars: Array(5).fill(starImage)
  },
  {
    name: "Ananya Gupta",
    position: "Junior Trader",
    review: "As a beginner, this journal has been incredibly useful. It’s intuitive and helps me stay on top of my trades. The performance summaries are my favorite feature.",
    img: "img/Ellipse 20.png",
    stars: Array(4).fill(starImage)
  },
  {
    name: "N Siddharth Reddy",
    position: "Investment Consultant",
    review: "Finally, a platform that combines journaling with detailed performance analysis. It’s been crucial in identifying where I need to improve. Can't wait to see it grow!",
    img: "img/Ellipse 20.png",
    stars: Array(5).fill(starImage)
  },
  {
    name: "Neha Desai",
    position: "Market Analyst",
    review: "The user interface is clean and easy to navigate. Journaling my trades has never been this organized. I appreciate the focus on performance metrics!",
    img: "img/Ellipse 20.png",
    stars: Array(4).fill(starImage)
  },
  {
    name: "Amitabh Joshi",
    position: "Wealth Manager",
    review: "This journal has become an essential part of my trading routine. The profit and loss breakdown is insightful, and the ability to track everything in one place is a big plus.",
    img: "img/Ellipse 20.png",
    stars: Array(5).fill(starImage)
  },
  {
    name: "M Kavita",
    position: "Risk Analyst",
    review: "My Trade Journal is perfect for traders who want to take their analysis to the next level. The detailed reviews of my trading days have been incredibly beneficial.",
    img: "img/Ellipse 20.png",
    stars: Array(4).fill(starImage)
  }
];

const Testimonials = () => {
  const [startIndex, setStartIndex] = useState(3);

  const handleLeftClick = () => {
    setStartIndex(prev => (prev - 3 < 0 ? 0 : prev - 3));
  };

  const handleRightClick = () => {
    setStartIndex(prev => (prev + 3 >= testimonialsData.length ? prev : prev + 3));
  };

  const isLeftDisabled = startIndex === 0;
  const isRightDisabled = startIndex + 3 >= testimonialsData.length;

  return (
    <section className="container4">
      <h3 className="journal">My Trading Journal <span id="revi">Reviews</span></h3>
      <div className="row justify-content-center">
        {testimonialsData.slice(startIndex, startIndex + 3).map((testimonial, index) => (
          <div className="col-xl-4 col-lg-4 d-flex justify-content-center" key={index}>
            <div className="review">
              <div className="img-svg">
                {testimonial.stars.map((star, idx) => (
                  <img src={star} alt="Star" key={idx} />
                ))}
              </div>
              <div className="inneralign">
                <div className="row">
                  <div className="col-12">
                    <span className="testimonial-name">{testimonial.name}</span><br />
                    <span className="position">{testimonial.position}</span>
                  </div>
                </div>
                <div className="review-text"><i>{testimonial.review}</i></div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="row navigation-icons">
        <div className={`col-5 col-md-5 ${isLeftDisabled ? 'disabled' : ''}`} id="lefticon" onClick={!isLeftDisabled ? handleLeftClick : undefined}>
          <img src="img/iconleft.png" alt="Left Icon" style={{ cursor: isLeftDisabled ? 'not-allowed' : 'pointer' }} />
        </div>
        <div className="col-2 col-md-2"><hr className="divider4" /></div>
        <div className={`col-5 col-md-5 ${isRightDisabled ? 'disabled' : ''}`} id="righticon" onClick={!isRightDisabled ? handleRightClick : undefined}>
          <img src="img/iconright.png" alt="Right Icon" style={{ cursor: isRightDisabled ? 'not-allowed' : 'pointer' }} />
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
