import React, { useEffect, useRef, useState } from "react";
import CloseIcon from "../../assets/images/closeIcon.svg";
import "./daily.css";
import { useDispatch, useSelector } from "react-redux";
import { getTradeById, updateTrade } from "../../store/slice/tradeLogSlice";
import lock from "../../assets/images/lock.svg";

const DailyQuestionnaire = ({ onClose, onSubmitAnswers, givenAnss = [] }) => {
  // const dispatch = useDispatch();
  const user = useSelector((state) => state?.auth?.user);
  const { plan } = user;
  // const reduxData = useSelector((state) => state);
  const answer1Ref = useRef(null);
  const answer2Ref = useRef(null);
  const answer3Ref = useRef(null);
  const answer4Ref = useRef(null);
  const answer5Ref = useRef(null);
  const answer6Ref = useRef(null);
  const answer7Ref = useRef(null);
  const answer8Ref = useRef(null);

  const handleSubmit = () => {
    const answer1 = answer1Ref.current.value || "";
    const answer2 = answer2Ref.current.value || "";
    const answer3 = answer3Ref.current.value || "";
    const answer4 = answer4Ref.current.value || "";
    const answer5 = answer5Ref.current.value || "";
    const answer6 = answer6Ref.current.value || "";
    const answer7 = answer7Ref.current.value || "";
    const answer8 = answer8Ref.current.value || "";

    const answers = [
      answer1,
      answer2,
      answer3,
      answer4,
      answer5,
      answer6,
      answer7,
      answer8,
    ];

    onSubmitAnswers(answers);

    onClose();
  };

  return (
    <div className="popUUpBg">
      <div className="filterPopUUp">
        <div className="filterPopUUpHeader">
          <p className="popUUpTitle">Daily Questionnaire</p>
          <div className="closeFilter" onClick={onClose}>
            <img src={CloseIcon} alt="Close" className="closeIcon" />
          </div>
        </div>
        <div className="filterPopUUBody">
          <div className="questionContainer">
            <p className="question">Did emotion influence my trade?</p>
            <input type="text" ref={answer1Ref} className="ipf" id="ip" />

            <p className="question">Did I follow my plan?</p>
            <input type="text" ref={answer2Ref} className="ipf" id="ip" />

            <p className="question">Was I confident in my decisions?</p>
            <input type="text" ref={answer3Ref} className="ipf" id="ip" />

            <p className="question">
              Did I experience regret or disappointment?
            </p>
            <input type="text" ref={answer4Ref} className="ipf" id="ip" />

            <p className="question">
              Did I take unnecessary risks or revenge trade?
            </p>
            <input type="text" ref={answer5Ref} className="ipf" id="ip" />

            <p className="question">Did I feel anxious or stressed?</p>
            <input type="text" ref={answer6Ref} className="ipf" id="ip" />

            <p className="question">
              Was I attached or averse to specific stocks or options?
            </p>
            <input type="text" ref={answer7Ref} className="ipf" id="ip" />

            <p className="question">Ideas for future improvements:</p>
            <input type="text" ref={answer8Ref} className="ipf" id="ip" />
          </div>

          {plan === "" || plan === "Pro" ? (
            <button onClick={handleSubmit} className="submitButton">
              Submit
            </button>
          ) : (
            <button className="submitButton" style={{paddingLeft: "35px", paddingRight: "35px"}}>
              <img src={lock} height={21} width={21} alt="locked" />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default DailyQuestionnaire;
