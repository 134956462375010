import React from 'react';
import "./Popup.css";
import { useSelector } from 'react-redux';
import moment from 'moment';

const Popup = ({ onClose, expired }) => {
  const user = useSelector((state) => state.auth.user);
  const expirationDate = moment(user.createdAt).add(30, 'days').format('MMMM DD, YYYY');

  const logout = () => {
    localStorage.clear();
    window.location.href = '/pricing_ranges';
  };

  if(expired){
    return (
      <div className="popup2">
        <div className='popup-content-2'>
          <div className='popup-matter-2'>
            <h2>Free Trial Expired. Please buy Basic or Pro subscription to continue using My Trade Journal.</h2>
            <button onClick={logout} className='popup-button'>OK</button>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="popup">
      <div className="popup-content">
        <div className='popup-matter'>
          Your free trial expires on {expirationDate}.</div> 
        <button onClick={onClose} className="popup-close">×</button>
      </div>
    </div>
  );
};

export default Popup;