import React, { useEffect, useState, useRef } from "react";
import Graph from "./Graphics";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getDrawDownGraphData } from "../../../store/slice/tradeAnalyticsSlice";

function DrawDownGraph() {
  const reduxData = useSelector((state) => state);
  const token = reduxData?.auth?.token;
  const dispatch = useDispatch();
  const [threshold, setThreshold] = useState(null);
  const inputRef = useRef(null);
  const { starttDate, enddDate } = useParams();

  const [modifiedData, setModifiedData] = useState([]);
  const colorsArray = [
    "#86E8D0",
    "#FFBC99",
    "#F5BCE9",
    "#B5E4CA",
    "#C3B6F6",
    "#FFBE5E",
  ];

  const handleClick = () => {
    setThreshold(inputRef.current.value);
  };

  useEffect(() => {
    if(threshold){
      let payloadUrl = `?threshold=${threshold}`
        if(starttDate && enddDate){
            payloadUrl = `?startDate=${starttDate}&endDate=${enddDate}&threshold=${threshold}`
        }
        dispatch(getDrawDownGraphData({token: token, values: payloadUrl}))
    }
  },[threshold, starttDate && enddDate]);

  useEffect(() => {
    if (reduxData.analytics.data?.graphs?.drawDownAnalysisGraphsData) {
      const { drawDownAnalysisGraphsData } = reduxData.analytics.data.graphs;
      const { titlesArray } = drawDownAnalysisGraphsData;
      const orderedData = titlesArray.map((title, index) => {
        const key = Object.keys(drawDownAnalysisGraphsData)[index];
        const values = drawDownAnalysisGraphsData[key];
  
        // Skip titlesArray itself
        if (key === "titlesArray") {
          return null;
        }

        return (
          <div className="col-md-6 col-12 chat-card" key={key}>
            <div className="card" style={{ marginBottom: "40px" }}>
              <div className="card-body" style={{ marginBottom: "20px" }}>
                <Graph
                  name={key}
                  graphData={values}
                  color={colorsArray[index % colorsArray.length]}
                  xTitle={title.x}
                  yTitle={title.y}
                />
              </div>
            </div>
          </div>
        );
      });

      setModifiedData(orderedData.filter(Boolean));
    }
  }, [reduxData.analytics.data?.graphs?.drawDownAnalysisGraphsData]);
  return (
    <div className="row mt-4 main-card-wrap">
      <div style={{ marginBottom: "30px" }}>
        <label style={{ marginRight: "5px" }}>PNL:</label>
        <input
          type="number"
          min={0}
          placeholder="Threshold"
          ref={inputRef}
          style={{ borderRadius: "3px" }}
        />
        <button
          type="button"
          onClick={handleClick}
          style={{
            backgroundColor: "white",
            border: "0.5px solid gray",
            marginLeft: "5px",
            borderRadius: "5px",
          }}
        >
          OK
        </button>
      </div>
      {modifiedData.length ? modifiedData : (
        <div>Please enter the DrawDown Threshold</div>
      )}
    </div>
  );
}

export default DrawDownGraph;
