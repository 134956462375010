export const insightsData=[
    " You need to improvise your strategies and trading rules to move towards profitability",
    "Good Job! You are a profitable trader.",
    "It's crucial that you focus more on following your trading rules to improve your results..",
    "You're doing a great job by following the rules. Staying consistent will make your results even better.",
    "You’ve experienced a 25% fall in your capital in the last few days. It's time to focus on reducing drawdowns to protect your investment.",
    "Maintaining lower drawdown levels contributes to mental peace and stress-free trading.",
    "You're winning more than you're losing, which is a good thing.",
    "You're losing more on losing days than you're winning on winning days. Reevaluate your risk management.",
    "It suggests you have a good risk-reward balance. Focus on strategies that yield more profitable days.",
    "It's time to rethink strategies that will result in more profitable days.",
    "You should adjust your risk-reward ratio to ensure a minimum of 1:2 for better results.",
    " A healthy risk-reward ratio is helping you remain profitable.",
    "You might be incurring significant losses due to excessive charges.",
    "It's a positive sign that for your trading performance that your trading charges are under control",
    "You might be incurring significant losses due to excessive penalties.",
    "It's a positive sign that for your trading performance that your trading penalties are under control",
    "You need to avoid trading on low conviction days as they are incurring losses",
    "You need to rethink and reevaluate your strategies on medium conviction days as they are still incurring losses",
    "You're experiencing losses even on high conviction days, it's time to reassess your strategies.",
    "You need to avoid trading on days when you're taking the most significant risk with your capital, as these days are causing losses and unnecessary stress.",
    "Even though the days with the highest capital risk are making money, ask yourself if the stress is worth it.",
    "You need to put in efforts to minimize slippage.",
    "You're managing slippages well, but there's room to further reduce them",
    "You are achieving a net ROI of at least 3% per month which indicates a well-performing trading setup.",
    "You should work on enhancing your trading performance to achieve a bare minimum of 3% returns per month.",
    "You should develop a contingency plan to prevent losing more than 1/5th capital in a single day.",
    "You've had over two weeks of consecutive losses. It's time to review and adjust your strategies to break this losing streak.",
    "The stress and drawdown you experience may not be justified by the returns you're generating. Consider reevaluating your current setup.",
]