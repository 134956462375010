import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import {
  tradeLogAdd,
  tradeLogEdit,
  tradeLogList,
  tradeLogUpdateFilter,
} from "../../store/slice/tradeLogSlice";
import { Formik, Field, ErrorMessage, Form } from "formik";
import * as Yup from "yup";
import Table from "react-bootstrap/Table";
import "./tradelog.scss";
import ReactDatePicker from "react-datepicker";
import lock from "../../assets/images/lock.svg";
import moment from "moment";
import {
  getColumnData,
  createColumnData,
  deleteColumnData,
} from "../../store/slice/newColumnSlice";
import eye from "../../assets/images/eye.svg";
import EditIcon from "../../assets/images/editFilter.svg";
import FilterIcon from "../../assets/images/filterIcon.svg";
import ExportIcon from "../../assets/images/export.svg";
import PopUpFilter from "../home/PopUpFilter";
import OutsideClick from "../home/OutsideClick";
import ArrowUP from "../../assets/images/arrowUp.svg";
import DownArrow from "../../assets/images/arrowDown.svg";
import { strategyList } from "../../store/slice/strategySlice";
import { tradingAccountList } from "../../store/slice/tradingAccountsSlice";
import { CSVLink } from "react-csv";
import NoTradeData from "./../../assets/images/noTradeLogData.svg";
import { useParams } from "react-router-dom";
import Pagination from "./Pagination";
import DailyQuestionnaire from "./DailyQuestionnaire";
import { getTradeById, updateTrade } from "../../store/slice/tradeLogSlice";
import Loader from "./../Loader";
import DailyQA from "./DailyQA";

const tableHeading = [
  "Date",
  "Asset class",
  "Script",
  "Buy/Sell",
  "Entry Price",
  "Position Size",
  "Stop Loss",
  "Target",
  "PnL",
  "Points Captured",
  "Net ROI",
  "Strategy",
  "Holding Trade",
  "Conviction",
  "Risk : Reward",
  "Reason for Trade",
  "Karma",
  "Comment",
  "% of account risked",
  "Charges",
  "Slippage",
  "Penalties",
  "Trading account",
  "Opening Balance",
  // "Image",
];

const sortableHeaders = [
  "Position Size",
  "Points Captured",
  "PnL",
  "% of account risked",
  "Slippage",
  "Net ROI",
  "Charges",
];

const tableHeadingObj = {
  Date: { label: "trade_date", type: "string" },
  "Asset class": { label: "asset_class", type: "string" },
  Script: { label: "position", type: "string" },
  "Buy/Sell": { label: "buy_sell", type: "string" },
  "Entry Price": { label: "entry_price", type: "number" },
  "Position Size": { label: "position_size", type: "number" },
  "Stop Loss": { label: "stop_loss", type: "number" },
  Target: { label: "trade_target", type: "number" },
  PnL: { label: "trade_pnl", type: "number" },
  "Points Captured": { label: "points_captured", type: "number" },
  "Net ROI": { label: "net_roi", type: "number" },
  Strategy: { label: "strategy_used", type: "string" },
  "Holding Trade": { label: "holding_trade_type", type: "string" },
  Conviction: { label: "trade_conviction", type: "string" },
  "Risk  : Reward": { label: "trade_risk", type: "string" },
  "Reason for Trade": { label: "reason_for_trade", type: "string" },
  Karma: { label: "trade_karma", type: "string" },
  Comment: { label: "comment", type: "string" },
  "% of account risked": {
    label: "percentage_of_account_risked",
    type: "number",
  },
  Charges: { label: "trade_charges", type: "number" },
  Slippage: { label: "trade_slippage", type: "number" },
  Penalties: { label: "trade_penalties", type: "number" },
  "Trading account": { label: "trading_account", type: "string" },
  "Opening Balance": { label: "opening_balance", type: "number" },
  // Image: { label: "image", type: "string" },
};

function TradeLog() {
  const dispatch = useDispatch();
  // const [image, setImage] = useState(null);
  const formikRef = useRef(null);
  const [showPrev, setShowPrev] = useState(false);
  const token = useSelector((state) => state?.auth?.token);
  const reduxData = useSelector((state) => state?.trades?.data);
  const isLoading = useSelector((state) => state?.trades?.isLoading);
  const { start, end } = useSelector((state) => state?.trades);
  const userData = localStorage.getItem("persist:root");
  const data = JSON.parse(userData);
  const { user } = JSON.parse(data.auth);
  const userDetails = useSelector((state) => state?.auth?.user);
  const { plan } = userDetails;
  const [col, setCol] = useState([]);
  const sortedTableHeading = tableHeading.slice();
  const sortable_TableHeadings = sortableHeaders.slice();
  const [tradeList, setTradeList] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const isAddedOrEdited = useSelector(
    (state) => state?.trades?.isAddedOrEdited
  );

  useEffect(() => {
    if (end) {
      setTradeList(
        () =>
          reduxData.length > 0 &&
          reduxData?.filter(
            (el) =>
              new Date(start) < new Date(el?.trade_date) &&
              new Date(end) > new Date(el?.trade_date)
          )
      );
    } else {
      setTradeList(() => reduxData.data);
    }
  }, [end, reduxData]);

  async function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
    });
  }

  useEffect(() => {
    if (reduxData?.data?.length) {
      setTradeList((_prev) => reduxData?.data);
    }
  }, [reduxData]);

  const columnDetail = useSelector((state) => state?.columnList?.data);
  const [strategies, setStrategies] = useState([]);
  const [accountList, setAccountList] = useState([]);
  const strategyData = useSelector((state) => state?.strategy?.data);
  const tradingAccounts = useSelector((state) => state?.tradingAccounts?.data);
  const [edit, setEdit] = useState(false);
  const [startDate1, setStartDate1] = useState(new Date());
  const [id, setId] = useState("");
  const [popUp, setPopUp] = useState(false);
  const [changes, setChanges] = useState(false);
  const [allIds, setAllIds] = useState([]);
  const { starttDate, enddDate } = useParams();
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    if (strategyData?.length) {
      setStrategies((_prev) => strategyData);
    }
  }, [strategyData]);

  useEffect(() => {
    if (tradingAccounts?.length) {
      setAccountList((_prev) => tradingAccounts);
    }
  }, [tradingAccounts]);

  const [pageDetail, setPageDetail] = useState({
    page: 1,
    pageSize: 10,
    enableNext: false,
    enablePrevious: false,
  });

  useEffect(() => {
    if (starttDate && enddDate) {
      let payloadUrl = `?startDate=${starttDate}&endDate=${enddDate}&pageSize=${pageDetail.pageSize}&page=${pageDetail.page}`;
      dispatch(tradeLogUpdateFilter({ token: token, values: payloadUrl }));
    } else {
      let payloadUrl = `&pageSize=${pageDetail.pageSize}&page=${pageDetail.page}`;
      dispatch(tradeLogList({ token: token, payloadUrl: payloadUrl }));
    }
    dispatch(strategyList(token));
    dispatch(tradingAccountList(token));
  }, [isAddedOrEdited === true, starttDate && enddDate]);

  useEffect(() => {
    dispatch(getColumnData(token));
  }, []);

  const tradeSchema = Yup.object().shape({
    asset_class: Yup.string(),
    position_size: Yup.number(),
    points_captured: Yup.number(),
    entry_price: Yup.number().required("*"),
    trade_pnl: Yup.number().required("*"),
    position: Yup.string().required("*"),
    buy_sell: Yup.string().required("*"),
    trade_karma: Yup.string(),
    trade_date: Yup.date().required("*"),
    holding_trade_type: Yup.string(),
    trade_charges: Yup.number(),
    trading_account: Yup.string(),
    stop_loss: Yup.number(),
    trade_conviction: Yup.string(),
    strategy_used: Yup.string(),
    trade_risk: Yup.string(),
    reason_for_trade: Yup.string(),
    percentage_of_account_risked: Yup.number(),
    trade_slippage: Yup.number(),
    trade_penalties: Yup.number(),
    trade_target: Yup.number(),
    net_roi: Yup.number(),
    opening_balance: Yup.number().when("trade_date", {
      is: (_tradeDate) => {
        return tradeList?.length === 0;
      },
      then: Yup.number().required("*"),
      otherwise: Yup.number().transform((value, originalObject) => {
        if (!value) {
          return calculateOpeningBalance(originalObject);
        }
        return value;
      }),
    }),
    comment: Yup.string(),
    questionnaire_answers: Yup.array().of(Yup.string()),
  });

  function calculateOpeningBalance(originalObject) {
    //Opening balance = prev opening balance + pnl - charges - penalties
    const pnl = originalObject?.trade_pnl;
    const charges = originalObject?.trade_charges;
    const penalties = originalObject?.trade_penalties;
    let newOpeningBalance = reduxData.lastAddedTradeOpeningBalance;
    if (pnl) {
      newOpeningBalance += pnl;
    }
    if (charges) {
      newOpeningBalance -= charges;
    }
    if (penalties) {
      newOpeningBalance -= penalties;
    }
    const calculatedBalance = newOpeningBalance;
    return calculatedBalance;
  }

  const apiUrl = process.env.REACT_APP_API_URL;

  const formRefs = {
    trade_date: useRef(null),
    position: useRef(null),
    buy_sell: useRef(null),
    // Add refs for other fields as necessary
    position_size: useRef(null),
    entry_price: useRef(null),
    trade_pnl: useRef(null),
    percentage_of_account_risked: useRef(null),
    trade_charges: useRef(null),
    trade_slippage: useRef(null),
    trade_penalties: useRef(null),
    opening_balance: useRef(null),
  };

  const handleSaveSubmit = (values, token) => {
    const scrollToField = (fieldName) => {
      const tdElement = formRefs[fieldName].current;
      if (tdElement) {
        const inputElement =
          tdElement.querySelector("input") || tdElement.querySelector("select"); // Adjust if you have other form elements
        if (inputElement) {
          inputElement.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "center",
          });
          inputElement.classList.add("highlight");
          setTimeout(() => {
            inputElement.classList.remove("highlight");
          }, 2500); // Remove the highlight after 2 seconds
        }
      }
    };

    if (values.trade_date === "" || values.trade_date === null) {
      alert("Trade Date must not be empty or Invalid");
      scrollToField("trade_date");
      return;
    }
    if (values.position === "") {
      scrollToField("position");
      return;
    }
    if (values.buy_sell === "") {
      scrollToField("buy_sell");
      return;
    }
    if (values.entry_price === null) {
      scrollToField("entry_price");
      return;
    }
    if (values.entry_price < 0) {
      alert("Entry Price must be a positive value");
      scrollToField("entry_price");
      return;
    }
    if (values.position_size < 0) {
      alert("Position size must be a positive value");
      scrollToField("position_size");
      return;
    }
    if (values.trade_pnl === null) {
      scrollToField("trade_pnl");
      return;
    }
    if (values.percentage_of_account_risked < 0) {
      alert("% of Account Risked must not be negative");
      scrollToField("percentage_of_account_risked");
      return;
    }
    if (values.trade_charges < 0) {
      alert("Trade Charges must be positive");
      scrollToField("trade_charges");
      return;
    }
    if (values.trade_slippage < 0) {
      alert("Slippage must be positive");
      scrollToField("trade_slippage");
      return;
    }
    if (values.trade_penalties < 0) {
      alert("Trade Penalties must be positive");
      scrollToField("trade_penalties");
      return;
    }

    if (tradeList.length === 0 && values.opening_balance === null) {
      alert("Opening balance must not be empty.");
      return; // Exit the function to prevent further code execution
    }

    if (values.opening_balance === null) {
      const tdd = sortTradeDataBy(tradeList);
      const openingBalance = tdd[0].opening_balance;
      values.opening_balance =
        openingBalance +
        values.trade_pnl -
        values.trade_penalties -
        values.trade_charges;
    }

    // Extract dynamic columns
    const dc = Object.keys(values)
      .filter((key) => columnDetail.some((col) => col.column_name === key))
      .map((key) => ({
        column_name: key,
        value: values[key],
      }));

    // Remove dynamic columns from values object
    const sv = Object.keys(values).reduce((acc, key) => {
      if (!columnDetail.some((col) => col.column_name === key)) {
        acc[key] = values[key];
      }
      return acc;
    }, {});

    // Add the dynamicColumn array to sanitizedValues
    sv.dynamicColumn = dc;
    let payloadUrl = `&pageSize=${pageDetail.pageSize}&page=${pageDetail.page}`;

    const filename = "tradelog";
    axios
      .post(`${apiUrl}/trade/?filename=${filename}`, sv, {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(tradeLogList({ token: token, payloadUrl: payloadUrl }));
        alert("Saved.");
      })
      .catch((error) => {
        console.error(error);
        alert("Error saving trade log.");
      });
  };

  const handleEditSubmit = () => {
    const hasEmptyFields = tradeList.some(
      (item) =>
        item.position === "" ||
        item.trade_date === null ||
        item.trade_date === "" ||
        item.trade_pnl === "" ||
        item.entry_price === "" ||
        item.position_size < 0 ||
        item.entry_price < 0 ||
        item.percentage_of_account_risked < 0 ||
        item.trade_charges < 0 ||
        item.trade_slippage < 0 ||
        item.trade_penalties < 0
    );

    if (hasEmptyFields) {
      if (
        tradeList.some(
          (item) => item.trade_date === "" || item.trade_date === null
        )
      ) {
        alert("Trade Date must not be empty.");
        return;
      }
      if (tradeList.some((item) => item.trade_pnl === "")) {
        alert("Trade PNL must not be empty.");
        return;
      }
      if (tradeList.some((item) => item.position === "")) {
        alert("Script must not be empty.");
        return;
      }
      if (tradeList.some((item) => item.trade_date === "")) {
        alert("Trade Date must not be empty.");
        return;
      }
      if (tradeList.some((item) => item.entry_price === "")) {
        alert("Entry Price must not be empty.");
        return;
      }
      if (tradeList.some((item) => item.entry_price < 0)) {
        alert("Entry Price must not be negative.");
        return;
      }
      if (tradeList.some((item) => item.position_size < 0)) {
        alert("Position Size must not be negative.");
        return;
      }
      if (tradeList.some((item) => item.percentage_of_account_risked < 0)) {
        alert("Percentage must not be negative.");
        return;
      }
      if (tradeList.some((item) => item.trade_charges < 0)) {
        alert("Trade charges must not be negative.");
        return;
      }
      if (tradeList.some((item) => item.trade_slippage < 0)) {
        alert("Trade Slippage must not be negative.");
        return;
      }
      if (tradeList.some((item) => item.trade_penalties < 0)) {
        alert("Trade Penalties must not be negative.");
        return;
      }
    }

    const customId = [...new Set(allIds)];
    const holdTrade = tradeList?.map((item) => {
      const tradeCopy = { ...item };
      delete tradeCopy.createdAt;
      delete tradeCopy.user_id;
      delete tradeCopy.id;
      return {
        id: item.id,
        data: tradeCopy,
      };
    });
    const allVal = holdTrade?.filter((item, i) => customId.includes(i));
    const payload = {
      token: token,
      values: allVal,
    };
    dispatch(tradeLogEdit(payload));
    setId("");
    setEdit(false);
  };

  function matchAndMapColumns(columnData, dynamicColumnData) {
    const result = [];

    // Check if columnData is an array
    if (Array.isArray(columnData)) {
      for (const columnItem of columnData) {
        const matchingDynamicColumn = dynamicColumnData.find(
          (dynamicColumnItem) =>
            dynamicColumnItem.column_name === columnItem.column_name
        );

        // If there is a match, add to the result
        if (matchingDynamicColumn) {
          result.push({
            name: columnItem.column_name,
            value: matchingDynamicColumn.value,
          });
        } else {
          // If no match, add an entry with an empty value
          result.push({
            name: columnItem.column_name,
            value: "-",
          });
        }
      }
    } else {
      console.error("Column data is not an array");
    }

    return result;
  }

  const closePopUp = () => {
    setPopUp((_prev) => false);
  };

  const togglePopUp = () => {
    setPopUp((_prev) => true);
  };
  const modifyExistingData = (field, id, value) => {
    setAllIds((prev) => [...prev, id]);
    setTradeList((prev) => {
      const hold = JSON.parse(JSON.stringify(prev)).map((item, i) => {
        if (i === id) {
          if (typeof item[field] === "number") {
            item[field] = parseFloat(value);
          } else {
            item[field] = value;
          }
        }
        return item;
      });

      return hold;
    });
    setChanges((prev) => !prev);
  };

  const modifyExistingDynamicColumnData = (field, id, value) => {
    setAllIds((prev) => [...prev, id]);
    setTradeList((prev) => {
      const hold = JSON.parse(JSON.stringify(prev)).map((item, i) => {
        if (i === id) {
          item.dynamicColumn = item.dynamicColumn.map((dynamicColumnItem) => {
            if (dynamicColumnItem.column_name === field) {
              dynamicColumnItem.value = value;
            }
            return dynamicColumnItem;
          });
        }
        return item;
      });

      return hold;
    });
    setChanges((prev) => !prev);
  };

  const ref = useRef();
  OutsideClick(ref, closePopUp);

  const [sort, setSort] = useState({
    label: "",
    sort: "ASC",
  });

  const sortTradeDataBy = (data) => {
    let sortedData;
    const arrayForSort = [...data];
    sortedData = arrayForSort.sort((a, b) => {
      const x = a?.trade_date;
      const y = b?.trade_date;
      if (x > y) {
        return -1;
      }
      if (x < y) {
        return 1;
      }
      return 0;
    });
    return sortedData;
  };

  function sortDataBy(data, byKey, order) {
    let sortedData;
    const arrayForSort = [...data];
    if (order === "ASC") {
      if (byKey.type === "string") {
        sortedData = arrayForSort.sort((a, b) => {
          let x = a[byKey.label]?.toLowerCase();
          let y = b[byKey.label]?.toLowerCase();
          if (x > y) {
            return 1;
          }
          if (x < y) {
            return -1;
          }
          return 0;
        });
      } else {
        sortedData = arrayForSort.sort((a, b) => {
          return a[byKey.label] - b[byKey.label];
        });
      }
    } else {
      if (byKey.type === "string") {
        sortedData = arrayForSort.sort((a, b) => {
          let x = a[byKey.label]?.toLowerCase();
          let y = b[byKey.label]?.toLowerCase();
          if (x < y) {
            return 1;
          }
          if (x > y) {
            return -1;
          }
          return 0;
        });
      } else {
        sortedData = arrayForSort.sort((a, b) => {
          return b[byKey.label] - a[byKey.label];
        });
      }
    }
    setTradeList(sortedData);
  }

  const [searchText, setSearchText] = useState("");
  const [noDataFound, setNoDataFound] = useState(false);

  const handleSearchInputChange = (event) => {
    setSearchText(event.target.value);
  };

  const [originalData, setOriginalData] = useState([]);

  useEffect(() => {
    if (!reduxData?.data) {
      setTradeList([]);
      setOriginalData([]);
      setNoDataFound(true);
      return;
    }

    if (originalData.length === 0) {
      setOriginalData(reduxData.data);
    }

    const filteredData = reduxData.data.filter((trade) => {
      return (
        (trade.asset_class && trade.asset_class.includes(searchText)) ||
        (trade.asset_class &&
          trade.asset_class.toLowerCase().includes(searchText)) ||
        (trade.holding_trade_type &&
          trade.holding_trade_type.toLowerCase().includes(searchText)) ||
        (trade.holding_trade_type &&
          trade.holding_trade_type.includes(searchText)) ||
        (trade.strategy_used &&
          trade.strategy_used.toLowerCase().includes(searchText)) ||
        (trade.strategy_used && trade.strategy_used.includes(searchText)) ||
        (trade.reason_for_trade &&
          trade.reason_for_trade.toLowerCase().includes(searchText)) ||
        (trade.reason_for_trade &&
          trade.reason_for_trade.includes(searchText)) ||
        (trade.buy_sell && trade.buy_sell.toLowerCase().includes(searchText)) ||
        (trade.buy_sell && trade.buy_sell.includes(searchText)) ||
        (trade.comment && trade.comment.toLowerCase().includes(searchText)) ||
        (trade.comment && trade.comment.includes(searchText)) ||
        (trade.trading_account &&
          trade.trading_account.toLowerCase().includes(searchText)) ||
        (trade.trading_account && trade.trading_account.includes(searchText)) ||
        (trade.position && trade.position.toLowerCase().includes(searchText)) ||
        (trade.position && trade.position.includes(searchText))
      );
    });

    if (filteredData.length === 0 && searchText.trim() !== "") {
      setTradeList(originalData);
      setNoDataFound(true);
    } else {
      setTradeList(filteredData);
      setNoDataFound(filteredData.length === 0 && searchText.trim() !== "");
    }
  }, [searchText, reduxData?.data]);

  const handleNext = () => {
    if (pageDetail.page < reduxData?.totalRecords / 10) {
      setPageDetail((prev) => {
        return {
          ...prev,
          page: prev.page + 1,
        };
      });
      let payloadUrl = `&pageSize=${pageDetail.pageSize}&page=${
        pageDetail.page + 1
      }`;
      dispatch(tradeLogList({ token: token, payloadUrl: payloadUrl }));
    }
  };

  const handlePrevious = () => {
    //handling function according to reduxData.totalRecords
    if (pageDetail.page > 0) {
      setPageDetail((prev) => ({ ...prev, page: prev.page - 1 }));
      let payloadUrl = `&pageSize=${pageDetail.pageSize}&page=${
        pageDetail.page - 1
      }`;
      dispatch(tradeLogList({ token: token, payloadUrl: payloadUrl }));
    }
  };

  const handlePageClick = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= reduxData?.totalRecords / 10) {
      setPageDetail((prev) => ({ ...prev, page: pageNumber }));
      let payloadUrl = `&pageSize=${pageDetail.pageSize}&page=${pageNumber}`;
      dispatch(tradeLogList({ token: token, payloadUrl: payloadUrl }));
    }
  };

  const handleFirst = () => {
    setPageDetail((prevState) => ({
      ...prevState,
      page: 1,
    }));
    let payloadUrl = `&pageSize=${pageDetail.pageSize}&page=${1}`;
    dispatch(tradeLogList({ token: token, payloadUrl: payloadUrl }));
  };

  const handleLast = () => {
    setPageDetail((prevState) => ({
      ...prevState,
      page: Math.ceil(reduxData.totalRecords / pageDetail.pageSize),
    }));
    let payloadUrl = `&pageSize=${pageDetail.pageSize}&page=${Math.ceil(
      reduxData.totalRecords / pageDetail.pageSize
    )}`;
    dispatch(tradeLogList({ token: token, payloadUrl: payloadUrl }));
  };

  const [showQuestionnaire, setShowQuesitonireModal] = useState(false);
  const [showQuestionnaireDQ, setShowQuesitonireDQ] = useState(false);
  const [questionnaireANS, setQuestionnaireANS] = useState();
  const closeQuestionnaire = () => {
    setShowQuesitonireModal(false);
    setShowQuesitonireDQ(false);
  };

  const openQuestionnaireDQ = (givenAns) => {
    setShowQuesitonireDQ(true);
    setQuestionnaireANS(givenAns);
  };

  const openQuestionnaire = () => {
    setShowQuesitonireModal(true);
  };

  const handleQuestionnaireSubmit = (answers, setFieldValue) => {
    setFieldValue("questionnaire_answers", answers);
  };

  useEffect(() => {
    dispatch(getColumnData(token));
  }, [dispatch]);

  const deleteColumn = async (columnId, columnName) => {
    try {
      // Dispatch deleteColumnData action to delete the column
      const response = await dispatch(
        deleteColumnData({
          token: token,
          columnId: columnId,
        })
      );

      axios
        .post(
          `${apiUrl}/trade/deleteDC`,
          { value: columnName },
          {
            headers: {
              "Content-Type": "application/json",
              authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          alert("Column deleted.");
        })
        .catch((error) => {
          console.error("Error deleting column:", error);
        });
    } catch (error) {
      console.error("Error deleting column:", error);
    }
  };

  const handleAddColumn = async (new_input) => {
    setCol((prevCol) => [
      ...prevCol,
      {
        token: token,
        data: new_input,
      },
    ]);
    dispatch(
      createColumnData({
        token: token,
        data: new_input,
      })
    );

    axios
      .post(
        `${apiUrl}/trade/createDC`,
        { value: new_input },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        alert("New Column created.");
      })
      .catch((error) => {
        console.error("Error adding new column:", error);
      });
  };

  const setCurrentTimeInIST = (date) => {
    if (date) {
      const dateWithTime = new Date(date);
      const currentTime = new Date();
      dateWithTime.setHours(currentTime.getHours());
      dateWithTime.setMinutes(currentTime.getMinutes());
      dateWithTime.setSeconds(currentTime.getSeconds());
      dateWithTime.setMilliseconds(currentTime.getMilliseconds());
      dateWithTime.setTime(dateWithTime.getTime());
      return dateWithTime;
    }
    return null;
  };

  const [popupVisible, setPopupVisible] = useState(false);
  const [popupPosition, setPopupPosition] = useState({ x: 0, y: 0 });

  const handleMouseEnter = (event) => {
    const { clientX, clientY } = event;
    setPopupPosition({ x: clientX, y: clientY });
    setPopupVisible(true);
  };

  const handleMouseLeave = () => {
    setPopupVisible(false);
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleSave = () => {
    setPopupVisible(false);
    setInputValue("");
  };

  return (
    <>
      {showQuestionnaireDQ && !edit && (
        <DailyQA
          onClose={closeQuestionnaire}
          givenAnss={questionnaireANS}
          isEdit={false}
        />
      )}
      {showQuestionnaireDQ && edit && (
        <DailyQA
          onClose={closeQuestionnaire}
          givenAnss={questionnaireANS}
          isEdit={true}
        />
      )}
      {tradeList && tradeList?.length > 0 && !isLoading ? (
        <div className="main-content demo-b">
          {popUp && (
            <div ref={ref}>
              <PopUpFilter
                closePopUp={closePopUp}
                setTradeList={setTradeList}
              />
            </div>
          )}
          <div className="customFilterButton">
            {edit ? (
              <>
                {" "}
                <ul>
                  <li
                    onClick={() => {
                      setEdit(false);
                    }}
                  >
                    Cancel
                  </li>
                  <li
                    onClick={() => {
                      handleEditSubmit();
                    }}
                  >
                    Save
                  </li>
                </ul>
              </>
            ) : (
              <>
                <div className="textbox">
                  <input
                    type="text"
                    value={searchText}
                    onChange={handleSearchInputChange}
                    placeholder="Search..."
                    style={{
                      width: "30%",
                      resize: "none",
                      padding: "10px",
                      border: "1px solid rgb(201, 201, 201)",
                      borderRadius: "12px",
                      height: "40px",
                      textAlign: "center",
                    }}
                  />
                  <ul>
                    <li
                      onClick={() => {
                        setEdit(true);
                      }}
                    >
                      Edit
                      <span>
                        <img
                          src={EditIcon}
                          alt="edit filter"
                          height={18}
                          width={18}
                        />
                      </span>
                    </li>
                    <li className="export-data">
                      <CSVLink
                        data={tradeList?.map((el) => ({
                          ...el,
                          trade_date: new Date(el.trade_date).toDateString(),
                          // trade_date: "ss"
                        }))}
                        headers={Object.keys(tableHeadingObj).map((heading) => {
                          return {
                            key: tableHeadingObj[heading].label,
                            label: heading?.toLowerCase().replace(/\s+/g, "_"),
                          };
                        })}
                        filename={"tradelog-exports.csv"}
                      >
                        Export
                        <img src={ExportIcon} alt="main filter" />
                      </CSVLink>
                    </li>
                    <li onClick={togglePopUp}>
                      Filters{" "}
                      <span>
                        <img src={FilterIcon} alt="main filter" />
                      </span>
                    </li>
                  </ul>
                </div>
              </>
            )}
          </div>
          {noDataFound && (
            <div
              style={{
                fontStyle: "italic",
                color: "red",
                textAlign: "left",
                marginLeft: "100px",
              }}
            >
              No Data Found
            </div>
          )}
          <div className="tradelog-tbl">
            <div className="table_wrapper">
              <div className="table-responsive">
                <Table cellSpacing="0" cellPadding="0">
                  <thead>
                    <tr>
                      {sortedTableHeading.map((header, _index) => (
                        <th key={header}>
                          {header}
                          {sortable_TableHeadings.includes(header) && (
                            <span
                              className="sort-arrow"
                              onClick={() =>
                                setSort((prev) => {
                                  let newSortOrder;
                                  if (prev?.label !== header) {
                                    newSortOrder = "ASC";
                                  } else {
                                    newSortOrder =
                                      prev.sort === "ASC" ? "DESC" : "ASC";
                                  }

                                  const newSort = {
                                    label: header,
                                    sort: newSortOrder,
                                  };
                                  sortDataBy(
                                    tradeList,
                                    tableHeadingObj[header],
                                    newSortOrder
                                  );
                                  return newSort;
                                })
                              }
                            >
                              <img
                                style={{ cursor: "pointer" }}
                                src={
                                  sort?.label === header
                                    ? sort?.sort === "ASC"
                                      ? ArrowUP
                                      : DownArrow
                                    : DownArrow
                                }
                              />
                            </span>
                          )}
                        </th>
                      ))}

                      <th>Daily Questionnaire</th>

                      {columnDetail?.length > 0 &&
                        columnDetail?.map((header, index) => (
                          <th key={index}>
                            {header?.column_name}{" "}
                            {edit && (
                              <button
                                className="add_button"
                                onClick={() => {
                                  deleteColumn(header?.id, header?.column_name);
                                }}
                              >
                                Delete Column
                              </button>
                            )}
                          </th>
                        ))}

                      <th>
                        {!edit && (
                          <div
                            className="add_button"
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                          >
                            {plan === "Pro" || plan === "" ? (
                              "+"
                            ) : (
                              <img
                                src={lock}
                                height={20}
                                width={20}
                                alt="locked"
                              />
                            )}
                            {popupVisible && (
                              <div
                                className={`popup ${
                                  popupVisible ? "popup-visible" : ""
                                }`}
                                style={{
                                  top: `${popupPosition.y}px`,
                                  left: `${popupPosition.x - 200}px`,
                                  padding: "10px",
                                }}
                              >
                                {plan === "Pro" || plan === "" ? (
                                  <>
                                    <div
                                      className="popup-header"
                                      style={{
                                        marginBottom: "5px",
                                        color: "black",
                                      }}
                                    >
                                      Add New Column
                                    </div>
                                    <input
                                      type="text"
                                      className="popupin"
                                      value={inputValue}
                                      onChange={handleInputChange}
                                      placeholder="New Column Name"
                                    />
                                    <button
                                      className="popupsav"
                                      onClick={() => {
                                        handleSave();
                                        handleAddColumn(inputValue);
                                      }}
                                    >
                                      Save
                                    </button>
                                  </>
                                ) : (
                                  <div
                                    className="popup-header"
                                    style={{
                                      color: "black",
                                      padding: "10px",
                                    }}
                                  >
                                    Subscribe to Pro
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        )}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <>
                      <Formik
                        initialValues={{
                          asset_class: "",
                          position_size: null,
                          points_captured: null,
                          trade_pnl: null,
                          position: "",
                          buy_sell: "",
                          entry_price: null,
                          trade_karma: "",
                          trade_date: null,
                          holding_trade_type: "",
                          trade_charges: "",
                          trading_account: "",
                          stop_loss: null,
                          trade_target: null,
                          trade_conviction: "",
                          strategy_used: "",
                          trade_risk: "1:1",
                          reason_for_trade: "",
                          percentage_of_account_risked: "",
                          // image: "",
                          trade_slippage: null,
                          trade_penalties: "",
                          net_roi: null,
                          opening_balance: null,
                          comment: "",
                          ...columnDetail?.reduce((acc, item) => {
                            return {
                              ...acc,
                              [item.column_name]: "",
                            };
                          }, {}),
                          dynamicColumn: [],
                          questionnaire_answers: Array(8).fill(""),
                        }}
                        validationSchema={tradeSchema}
                        innerRef={(instance) => (formikRef.current = instance)}
                        // onSubmit={(values) => {
                        //   handleSaveSubmit(values, token);
                        // }}
                      >
                        {({ values, setFieldValue }) =>
                          !edit && (
                            <tr key={"first"} className="first">
                              <td ref={formRefs.trade_date}>
                                <ReactDatePicker
                                  id="trade_date"
                                  name="trade_date"
                                  closeOnScroll={true}
                                  placeholderText="Select Date"
                                  selected={startDate}
                                  onChange={(date) => {
                                    const dateWithCurrentTimeIST =
                                      setCurrentTimeInIST(date);
                                    setStartDate(dateWithCurrentTimeIST);
                                    setFieldValue(
                                      "trade_date",
                                      dateWithCurrentTimeIST
                                    );
                                  }}
                                  dateFormat="yyyy-MM-dd"
                                  maxDate={new Date()}
                                />
                                <ErrorMessage name="trade_date">
                                  {() => (
                                    <span
                                      style={{ color: "red", fontSize: "20px" }}
                                    >
                                      <b>*</b>
                                    </span>
                                  )}
                                </ErrorMessage>
                              </td>
                              <td>
                                <Field as="select" name="asset_class">
                                  <option value="" disabled>
                                    Select
                                  </option>
                                  <option value="Equity">Equity</option>
                                  <option value="Futures">Futures</option>
                                  <option value="Options">Options</option>
                                  <option value="Currency">Currency</option>
                                  <option value="Commodity">Commodity</option>
                                  <option value="Crypto">Crypto</option>
                                </Field>
                                <ErrorMessage
                                  name="asset_class"
                                  component="div"
                                />
                              </td>
                              <td ref={formRefs.position}>
                                <Field type="text" name="position" />
                                <ErrorMessage name="position">
                                  {() => (
                                    <span
                                      style={{ color: "red", fontSize: "20px" }}
                                    >
                                      <b>*</b>
                                    </span>
                                  )}
                                </ErrorMessage>
                              </td>
                              <td id="bu_se" ref={formRefs.buy_sell}>
                                <Field as="select" name="buy_sell">
                                  <option value="" disabled>
                                    Select
                                  </option>
                                  <option value="Buy">Buy</option>
                                  <option value="Sell">Sell</option>
                                </Field>
                                <ErrorMessage name="buy_sell">
                                  {() => (
                                    <div
                                      style={{ color: "red", fontSize: "20px" }}
                                    >
                                      <b>*</b>
                                    </div>
                                  )}
                                </ErrorMessage>
                              </td>
                              <td ref={formRefs.entry_price}>
                                <Field
                                  type="number"
                                  name="entry_price"
                                  min="0"
                                />
                                <ErrorMessage name="entry_price">
                                  {() => (
                                    <span
                                      style={{ color: "red", fontSize: "20px" }}
                                    >
                                      <b>*</b>
                                    </span>
                                  )}
                                </ErrorMessage>
                              </td>
                              <td ref={formRefs.position_size}>
                                <Field
                                  type="number"
                                  name="position_size"
                                  min="0"
                                />
                              </td>
                              <td>
                                <Field type="number" name="stop_loss" />
                              </td>
                              <td>
                                <Field type="number" name="trade_target" />
                              </td>
                              <td ref={formRefs.trade_pnl}>
                                <Field type="number" name="trade_pnl" />
                                <ErrorMessage name="trade_pnl">
                                  {() => (
                                    <span
                                      style={{ color: "red", fontSize: "20px" }}
                                    >
                                      <b>*</b>
                                    </span>
                                  )}
                                </ErrorMessage>
                              </td>
                              <td>
                                <Field type="number" name="points_captured" />
                              </td>
                              <td>
                                <Field type="number" name="net_roi" />
                              </td>
                              <td>
                                <Field as="select" name="strategy_used">
                                  <option>Select</option>
                                  {strategies?.length > 0 &&
                                    strategies.map((el, index) => (
                                      <option key={index}>
                                        {el.strategies_name}
                                      </option>
                                    ))}
                                </Field>
                                <ErrorMessage
                                  name="strategy_used"
                                  component="div"
                                />
                              </td>
                              <td>
                                <Field as="select" name="holding_trade_type">
                                  <option>Select</option>
                                  <option value="Positional">Positional</option>
                                  <option value="Intraday">Intraday</option>
                                  <option value="Swing">Swing</option>
                                  <option value="Short Term">Short Term</option>
                                  <option value="Long Term">Long Term</option>
                                  <option value="Expiry">Expiry</option>
                                  <option value="BTST">BTST</option>
                                </Field>
                                <ErrorMessage
                                  name="holding_trade_type"
                                  component="div"
                                />
                              </td>
                              <td>
                                <Field as="select" name="trade_conviction">
                                  <option>Select</option>
                                  <option value="Low">Low</option>
                                  <option value="Medium">Medium</option>
                                  <option value="High">High</option>
                                </Field>
                                <ErrorMessage
                                  name="trade_conviction"
                                  component="div"
                                />
                              </td>

                              <td>
                                <input
                                  className="w-[50%]"
                                  type="number"
                                  min="0"
                                  onChange={(e) => {
                                    setFieldValue(
                                      "trade_risk",
                                      `${e.target.value}:${
                                        values?.trade_risk
                                          ?.replace(/ /g, "")
                                          .split(/:/g)[1] || 1
                                      }`
                                    );
                                  }}
                                  value={
                                    values?.trade_risk
                                      ?.replace(/ /g, "")
                                      .split(/:/g)[0] || 1
                                  }
                                />
                                <span className="font-bold, mx-2 text-center text-2xl">
                                  :
                                </span>
                                <input
                                  className="w-[50%]"
                                  type="number"
                                  min="0"
                                  onChange={(e) => {
                                    setFieldValue(
                                      "trade_risk",
                                      `${
                                        values?.trade_risk
                                          ?.replace(/ /g, "")
                                          .split(/:/g)[0] || 1
                                      }:${e.target.value}`
                                    );
                                  }}
                                  value={
                                    values?.trade_risk
                                      ?.replace(/ /g, "")
                                      .split(/:/g)[1] || 1
                                  }
                                />
                              </td>
                              <td>
                                <Field type="text" name="reason_for_trade" />
                                <ErrorMessage
                                  name="reason_for_trade"
                                  component="div"
                                />
                              </td>
                              <td>
                                <Field as="select" name="trade_karma">
                                  <option>Select</option>
                                  <option value="Satisfied">Satisfied</option>
                                  <option value="Unsatisfied">
                                    Unsatisfied
                                  </option>
                                </Field>
                                <ErrorMessage
                                  name="trade_karma"
                                  component="div"
                                />
                              </td>
                              <td>
                                <Field type="text" name="comment" />
                                <ErrorMessage name="comment" component="div" />
                              </td>
                              <td ref={formRefs.percentage_of_account_risked}>
                                <Field
                                  type="number"
                                  name="percentage_of_account_risked"
                                  min="0"
                                />
                                <ErrorMessage
                                  name="percentage_of_account_risked"
                                  component="div"
                                />
                              </td>
                              <td ref={formRefs.trade_charges}>
                                <Field
                                  type="number"
                                  name="trade_charges"
                                  min="0"
                                />
                                <ErrorMessage name="trade_charges">
                                  {() => (
                                    <span
                                      style={{ color: "red", fontSize: "20px" }}
                                    >
                                      <b>*</b>
                                    </span>
                                  )}
                                </ErrorMessage>
                              </td>
                              <td ref={formRefs.trade_slippage}>
                                <Field type="number" name="trade_slippage" />
                              </td>
                              <td ref={formRefs.trade_penalties}>
                                <Field
                                  type="number"
                                  name="trade_penalties"
                                  min="0"
                                />
                                <ErrorMessage name="trade_penalties">
                                  {() => (
                                    <span
                                      style={{ color: "red", fontSize: "20px" }}
                                    >
                                      <b>*</b>
                                    </span>
                                  )}
                                </ErrorMessage>
                              </td>
                              <td>
                                <Field as="select" name="trading_account">
                                  <option>Select</option>
                                  {accountList.map((account) => (
                                    <option key={account.account_Id}>
                                      {account?.trading_account}
                                    </option>
                                  ))}
                                </Field>
                                <ErrorMessage
                                  name="trading_account"
                                  component="div"
                                />
                              </td>
                              <td>
                                <Field type="number" name="opening_balance" />
                                <ErrorMessage name="opening_balance">
                                  {() => (
                                    <span
                                      style={{ color: "red", fontSize: "20px" }}
                                    >
                                      <b>*</b>
                                    </span>
                                  )}
                                </ErrorMessage>
                              </td>
                              {/* <td className="special-col">
                                <input
                                  type="file"
                                  name="image"
                                  id="image"
                                  onChange={(e) => setImage(e.target.files[0])}
                                />
                                {showPrev && (
                                  <div className="img-preview">
                                    {image && (
                                      <img src={URL.createObjectURL(image)} />
                                    )}
                                  </div>
                                )}

                                <img
                                  src={eye}
                                  alt="Preview"
                                  height={21}
                                  className="prev_eye"
                                  width={21}
                                  onClick={() => setShowPrev((prev) => !prev)}
                                />
                                <ErrorMessage name="image" component="div" />
                              </td> */}

                              <td>
                                <button
                                  onClick={openQuestionnaire}
                                  type="button"
                                  className="fill_button"
                                >
                                  Fill up
                                </button>
                                {showQuestionnaire && !edit && (
                                  <DailyQuestionnaire
                                    onClose={closeQuestionnaire}
                                    onSubmitAnswers={(answers) =>
                                      handleQuestionnaireSubmit(
                                        answers,
                                        setFieldValue
                                      )
                                    }
                                  />
                                )}
                              </td>

                              {columnDetail?.length > 0 &&
                                columnDetail?.map(
                                  (items) =>
                                    items?.column_name && (
                                      <td>
                                        <Field
                                          type="text"
                                          name={`${items?.column_name}`}
                                        />
                                        <ErrorMessage
                                          name={`${items?.column_name}`}
                                          component="div"
                                        />
                                      </td>
                                    )
                                )}

                              <td>
                                <button
                                  type="submit"
                                  className="submit-btn"
                                  onClick={() => {
                                    handleSaveSubmit(values, token);
                                  }}
                                >
                                  Save
                                </button>
                              </td>
                            </tr>
                          )
                        }
                      </Formik>
                    </>

                    {tradeList?.length > 0 &&
                      tradeList?.map((item, index) => {
                        return (
                          <>
                            <Formik
                              key={index}
                              initialValues={{
                                asset_class: item?.asset_class || "",
                                position_size: item?.position_size || "",
                                points_captured: item?.points_captured || "",
                                trade_pnl: item?.trade_pnl || "",
                                entry_price: item?.entry_price || "",
                                position: item?.position || "",
                                buy_sell: item?.buy_sell || "",
                                trade_karma: item?.trade_karma || "",
                                trade_date:
                                  new Date(item?.trade_date) || null || "",
                                holding_trade_type:
                                  item?.holding_trade_type || "",
                                trade_charges: item?.trade_charges || "",
                                trading_account: item?.trading_account || "",
                                stop_loss: item?.stop_loss || "",
                                trade_target: item?.trade_target || "",
                                trade_conviction: item?.trade_conviction || "",
                                strategy_used: item?.strategy_used || "",
                                trade_risk: item?.trade_risk || "",
                                reason_for_trade: item?.reason_for_trade || "",
                                percentage_of_account_risked:
                                  item?.percentage_of_account_risked || "",
                                // image: item?.image || "",
                                trade_slippage: item?.trade_slippage || "",
                                trade_penalties: item?.trade_penalties || "",
                                net_roi: item?.net_roi || "",
                                opening_balance: item?.opening_balance || "",
                                comment: item?.comment || "",
                                questionnaire_answers:
                                  item?.questionnaire_answers || [],
                                dynamicColumn:
                                  item?.dynamicColumn.length > 0
                                    ? matchAndMapColumns(
                                        columnDetail,
                                        item?.dynamicColumn
                                      )
                                    : [],
                              }}
                              validationSchema={tradeSchema}
                              innerRef={formikRef}
                              // onSubmit={handlesSubmit}
                            >
                              {({ values, setFieldValue }) => {
                                return (
                                  <tr key={index} className={index}>
                                    <td>
                                      {id === item?.id || edit ? (
                                        <ReactDatePicker
                                          id="trade_date"
                                          name="trade_date"
                                          closeOnScroll={true}
                                          selected={
                                            values?.trade_date || startDate1
                                          }
                                          onChange={(date) => {
                                            const dateWithCurrentTimeIST =
                                              setCurrentTimeInIST(date);
                                            setStartDate1(
                                              dateWithCurrentTimeIST
                                            );
                                            setFieldValue(
                                              "trade_date",
                                              dateWithCurrentTimeIST
                                            );
                                            modifyExistingData(
                                              "trade_date",
                                              index,
                                              dateWithCurrentTimeIST
                                            );
                                          }}
                                          dateFormat="yyyy-MM-dd"
                                          maxDate={new Date()}
                                        />
                                      ) : (
                                        new Date(
                                          item?.trade_date
                                        ).toLocaleDateString("en-CA")
                                      )}
                                    </td>
                                    <td>
                                      <Field
                                        style={{ border: "none" }}
                                        as="select"
                                        name="asset_class"
                                        id="asset_class"
                                        value={item?.asset_class}
                                        onChange={(e) =>
                                          modifyExistingData(
                                            e.target.name,
                                            index,
                                            e.target.value
                                          )
                                        }
                                        disabled={!edit}
                                      >
                                        <option value="" disabled>
                                          Select
                                        </option>
                                        <option value="Equity">Equity</option>
                                        <option value="Futures">Futures</option>
                                        <option value="Options">Options</option>
                                        <option value="Currency">
                                          Currency
                                        </option>
                                        <option value="Commodity">
                                          Commodity
                                        </option>
                                        <option value="Crypto">Crypto</option>
                                      </Field>
                                    </td>
                                    <td>
                                      {id === item?.id || edit ? (
                                        <>
                                          <Field
                                            type="text"
                                            name="position"
                                            onChange={(e) => {
                                              modifyExistingData(
                                                e.target.name,
                                                index,
                                                e.target.value
                                              );
                                            }}
                                            value={item?.position}
                                          />
                                          <ErrorMessage
                                            name="position"
                                            component="div"
                                          />
                                        </>
                                      ) : (
                                        item?.position
                                      )}
                                    </td>
                                    <td>
                                      <Field
                                        style={{ border: "none" }}
                                        as="select"
                                        name="buy_sell"
                                        disabled={!edit}
                                        value={item?.buy_sell}
                                        onChange={(e) =>
                                          modifyExistingData(
                                            e.target.name,
                                            index,
                                            e.target.value
                                          )
                                        }
                                      >
                                        <option value="" disabled>
                                          Select
                                        </option>
                                        <option value="Buy">Buy</option>
                                        <option value="Sell">Sell</option>
                                      </Field>
                                      <ErrorMessage
                                        name="buy_sell"
                                        component="div"
                                      />
                                    </td>
                                    <td>
                                      {id === item?.id || edit ? (
                                        <>
                                          <Field
                                            type="number"
                                            name="entry_price"
                                            min="0"
                                            onChange={(e) => {
                                              modifyExistingData(
                                                e.target.name,
                                                index,
                                                e.target.value
                                              );
                                            }}
                                            value={item?.entry_price}
                                          />
                                        </>
                                      ) : (
                                        ` ₹ ${item?.entry_price}`
                                      )}
                                    </td>
                                    <td>
                                      {id === item?.id || edit ? (
                                        <>
                                          <Field
                                            type="number"
                                            name="position_size"
                                            min="0"
                                            onChange={(e) =>
                                              modifyExistingData(
                                                e.target.name,
                                                index,
                                                e.target.valueAsNumber
                                              )
                                            }
                                            value={item?.position_size}
                                          />
                                        </>
                                      ) : (
                                        item?.position_size
                                      )}
                                    </td>
                                    <td>
                                      {id === item?.id || edit ? (
                                        <>
                                          <Field
                                            type="number"
                                            name="stop_loss"
                                            onChange={(e) =>
                                              modifyExistingData(
                                                e.target.name,
                                                index,
                                                e.target.valueAsNumber
                                              )
                                            }
                                            value={item?.stop_loss}
                                          />
                                        </>
                                      ) : (
                                        item?.stop_loss
                                      )}
                                    </td>
                                    <td>
                                      {id === item?.id || edit ? (
                                        <>
                                          <Field
                                            type="number"
                                            name="trade_target"
                                            onChange={(e) =>
                                              modifyExistingData(
                                                e.target.name,
                                                index,
                                                e.target.value
                                              )
                                            }
                                            value={item?.trade_target}
                                          />
                                        </>
                                      ) : (
                                        item?.trade_target
                                      )}
                                    </td>
                                    <td>
                                      {id === item?.id || edit ? (
                                        <>
                                          <Field
                                            type="number"
                                            name="trade_pnl"
                                            onChange={(e) => {
                                              modifyExistingData(
                                                e.target.name,
                                                index,
                                                e.target.value
                                              );
                                            }}
                                            value={item?.trade_pnl}
                                          />
                                        </>
                                      ) : (
                                        ` ₹ ${item?.trade_pnl}`
                                      )}
                                    </td>
                                    <td>
                                      {id === item?.id || edit ? (
                                        <>
                                          <Field
                                            type="number"
                                            name="points_captured"
                                            onChange={(e) =>
                                              modifyExistingData(
                                                e.target.name,
                                                index,
                                                e.target.value
                                              )
                                            }
                                            value={item?.points_captured}
                                          />
                                        </>
                                      ) : (
                                        item?.points_captured
                                      )}
                                    </td>
                                    <td>
                                      {id === item?.id || edit ? (
                                        <>
                                          <Field
                                            type="number"
                                            name="net_roi"
                                            onChange={(e) =>
                                              modifyExistingData(
                                                e.target.name,
                                                index,
                                                e.target.value
                                              )
                                            }
                                            value={item?.net_roi}
                                          />
                                        </>
                                      ) : (
                                        item?.net_roi
                                      )}
                                    </td>
                                    <td>
                                      {id === item?.id || edit ? (
                                        <>
                                          <Field
                                            as="select"
                                            style={{ border: "none" }}
                                            name="strategy_used"
                                            disabled={!edit}
                                            value={item?.strategy_used}
                                            onChange={(e) =>
                                              modifyExistingData(
                                                e.target.name,
                                                index,
                                                e.target.value
                                              )
                                            }
                                          >
                                            <option>Select</option>
                                            {strategies?.length > 0 &&
                                              strategies.map((el, index) => (
                                                <option key={index}>
                                                  {el.strategies_name}
                                                </option>
                                              ))}
                                          </Field>
                                          <ErrorMessage
                                            name="strategy_used"
                                            component="div"
                                          />
                                        </>
                                      ) : (
                                        item?.strategy_used || "-"
                                      )}
                                    </td>
                                    <td>
                                      <Field
                                        as="select"
                                        style={{ border: "none" }}
                                        name="holding_trade_type"
                                        disabled={!edit}
                                        onChange={(e) =>
                                          modifyExistingData(
                                            e.target.name,
                                            index,
                                            e.target.value
                                          )
                                        }
                                        value={item?.holding_trade_type}
                                      >
                                        <option>Select</option>
                                        <option value="Positional">
                                          Positional
                                        </option>
                                        <option value="Intraday">
                                          Intraday
                                        </option>
                                        <option value="Swing">Swing</option>
                                        <option value="Short Term">
                                          Short Term
                                        </option>
                                        <option value="Long Term">
                                          Long Term
                                        </option>
                                        <option value="Expiry">Expiry</option>
                                        <option value="BTST">BTST</option>
                                      </Field>
                                      <ErrorMessage
                                        name="holding_trade_type"
                                        component="div"
                                      />
                                    </td>
                                    <td>
                                      <Field
                                        as="select"
                                        style={{ border: "none" }}
                                        name="trade_conviction"
                                        disabled={!edit}
                                        value={item?.trade_conviction}
                                        onChange={(e) =>
                                          modifyExistingData(
                                            e.target.name,
                                            index,
                                            e.target.value
                                          )
                                        }
                                      >
                                        <option>Select</option>
                                        <option value="Low">Low</option>
                                        <option value="Medium">Medium</option>
                                        <option value="High">High</option>
                                      </Field>
                                    </td>
                                    <td>
                                      {id === item?.id || edit ? (
                                        <>
                                          <input
                                            className="w-[50%]"
                                            type="number"
                                            min="0"
                                            onChange={(e) => {
                                              modifyExistingData(
                                                "trade_risk",
                                                index,
                                                `${e.target.value}:${
                                                  item?.trade_risk
                                                    ?.replace(/ /g, "")
                                                    .split(/:/g)[1] || 1
                                                }`
                                              );
                                            }}
                                            value={
                                              item?.trade_risk
                                                ?.replace(/ /g, "")
                                                .split(/:/g)[0] || 1
                                            }
                                          />
                                          <span className="font-bold, mx-2 text-center text-2xl">
                                            :
                                          </span>
                                          <input
                                            className="w-[50%]"
                                            type="number"
                                            min="0"
                                            onChange={(e) => {
                                              modifyExistingData(
                                                "trade_risk",
                                                index,
                                                `${
                                                  item?.trade_risk
                                                    ?.replace(/ /g, "")
                                                    .split(/:/g)[0] || 1
                                                }:${e.target.value}`
                                              );
                                            }}
                                            value={
                                              item?.trade_risk
                                                ?.replace(/ /g, "")
                                                .split(/:/g)[1] || 1
                                            }
                                          />
                                        </>
                                      ) : (
                                        item?.trade_risk || "1:1"
                                      )}
                                    </td>
                                    <td>
                                      {id === item?.id || edit ? (
                                        <>
                                          <Field
                                            type="text"
                                            name="reason_for_trade"
                                            onChange={(e) =>
                                              modifyExistingData(
                                                e.target.name,
                                                index,
                                                e.target.value
                                              )
                                            }
                                            value={item?.reason_for_trade}
                                          />
                                          <ErrorMessage
                                            name="reason_for_trade"
                                            component="div"
                                          />
                                        </>
                                      ) : (
                                        item?.reason_for_trade || "-"
                                      )}
                                    </td>
                                    <td>
                                      <Field
                                        as="select"
                                        style={{ border: "none" }}
                                        name="trade_karma"
                                        disabled={!edit}
                                        value={item?.trade_karma}
                                        onChange={(e) =>
                                          modifyExistingData(
                                            e.target.name,
                                            index,
                                            e.target.value
                                          )
                                        }
                                      >
                                        <option>Select</option>
                                        <option value="Satisfied">
                                          Satisfied
                                        </option>
                                        <option value="Unsatisfied">
                                          Unsatisfied
                                        </option>
                                      </Field>
                                      <ErrorMessage
                                        name="trade_karma"
                                        component="div"
                                      />
                                    </td>
                                    <td>
                                      {id === item?.id || edit ? (
                                        <>
                                          <Field
                                            type="text"
                                            name="comment"
                                            onChange={(e) =>
                                              modifyExistingData(
                                                e.target.name,
                                                index,
                                                e.target.value
                                              )
                                            }
                                            value={item?.comment}
                                          />
                                          <ErrorMessage
                                            name="comment"
                                            component="div"
                                          />
                                        </>
                                      ) : (
                                        item?.comment || "-"
                                      )}
                                    </td>
                                    <td>
                                      {id === item?.id || edit ? (
                                        <>
                                          <Field
                                            type="number"
                                            name="percentage_of_account_risked"
                                            min="0"
                                            onChange={(e) =>
                                              modifyExistingData(
                                                e.target.name,
                                                index,
                                                e.target.value
                                              )
                                            }
                                            value={
                                              item?.percentage_of_account_risked
                                            }
                                          />
                                        </>
                                      ) : (
                                        item?.percentage_of_account_risked
                                      )}
                                    </td>
                                    <td>
                                      {id === item?.id || edit ? (
                                        <>
                                          <Field
                                            type="number"
                                            name="trade_charges"
                                            min="0"
                                            onChange={(e) =>
                                              modifyExistingData(
                                                e.target.name,
                                                index,
                                                e.target.value
                                              )
                                            }
                                            value={item?.trade_charges}
                                          />
                                          <ErrorMessage
                                            name="trade_charges"
                                            component="div"
                                          />
                                        </>
                                      ) : (
                                        ` ₹ ${item?.trade_charges}`
                                      )}
                                    </td>
                                    <td>
                                      {id === item?.id || edit ? (
                                        <>
                                          <Field
                                            type="number"
                                            name="trade_slippage"
                                            min="0"
                                            onChange={(e) =>
                                              modifyExistingData(
                                                e.target.name,
                                                index,
                                                e.target.value
                                              )
                                            }
                                            value={item?.trade_slippage}
                                          />
                                        </>
                                      ) : (
                                        item?.trade_slippage
                                      )}
                                    </td>
                                    <td>
                                      {id === item?.id || edit ? (
                                        <>
                                          <Field
                                            type="number"
                                            name="trade_penalties"
                                            min="0"
                                            onChange={(e) =>
                                              modifyExistingData(
                                                e.target.name,
                                                index,
                                                e.target.value
                                              )
                                            }
                                            value={item?.trade_penalties}
                                          />
                                        </>
                                      ) : (
                                        ` ₹ ${item?.trade_penalties}`
                                      )}
                                    </td>
                                    <td>
                                      {id === item?.id || edit ? (
                                        <>
                                          <Field
                                            as="select"
                                            style={{ border: "none" }}
                                            name="trading_account"
                                            disabled={!edit}
                                            value={item?.trading_account}
                                            onChange={(e) =>
                                              modifyExistingData(
                                                e.target.name,
                                                index,
                                                e.target.value
                                              )
                                            }
                                          >
                                            <option>Select</option>
                                            {accountList.map((account) => (
                                              <option key={account.account_Id}>
                                                {account?.trading_account}
                                              </option>
                                            ))}
                                          </Field>
                                        </>
                                      ) : (
                                        item?.trading_account || "-"
                                      )}
                                    </td>
                                    <td>
                                      {id === item?.id || edit ? (
                                        <>
                                          <Field
                                            type="number"
                                            name="opening_balance"
                                            onChange={(e) =>
                                              modifyExistingData(
                                                e.target.name,
                                                index,
                                                e.target.value
                                              )
                                            }
                                            value={
                                              item?.opening_balance &&
                                              item?.opening_balance
                                            }
                                          />
                                        </>
                                      ) : (
                                        item?.opening_balance &&
                                        parseFloat(
                                          item?.opening_balance
                                        ).toFixed(2)
                                      )}
                                    </td>
                                    {/* <td>
                                      <img
                                        src={item?.image}
                                        style={{
                                          width: "100%",
                                          display: "flex",
                                          margin: "auto",
                                        }}
                                      />
                                    </td> */}
                                    <td>
                                      <button
                                        style={{
                                          backgroundColor: "transparent",
                                          border: "none",
                                        }}
                                        onClick={() => {
                                          openQuestionnaireDQ(
                                            item?.questionnaire_answers
                                          );
                                        }}
                                        type="button"
                                      >
                                        <img
                                          src={edit ? EditIcon : eye}
                                          alt={edit ? "Edit" : "View"}
                                          height={21}
                                          width={21}
                                        />
                                      </button>
                                    </td>

                                    <>
                                      {item?.dynamicColumn?.length > 0 &&
                                        matchAndMapColumns(
                                          columnDetail,
                                          item?.dynamicColumn
                                        ).map((items) => (
                                          <td>
                                            {id === item?.id || edit ? (
                                              <>
                                                <Field
                                                  type="text"
                                                  name={`${items?.name}`}
                                                  onChange={(e) => {
                                                    modifyExistingDynamicColumnData(
                                                      e.target.name,
                                                      index,
                                                      e.target.value
                                                    );
                                                  }}
                                                  value={
                                                    matchAndMapColumns(
                                                      columnDetail,
                                                      item?.dynamicColumn
                                                    ).find(
                                                      (el) =>
                                                        el?.name === items?.name
                                                    )?.value
                                                  }
                                                />
                                                <ErrorMessage
                                                  name={`${items?.name}`}
                                                  component="div"
                                                />
                                              </>
                                            ) : (
                                              items?.value
                                            )}
                                          </td>
                                        ))}
                                    </>
                                  </tr>
                                );
                              }}
                            </Formik>
                          </>
                        );
                      })}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
          <Pagination
            pageDetail={pageDetail}
            reduxData={reduxData}
            handlePrevious={handlePrevious}
            handleNext={handleNext}
            handlePageClick={handlePageClick}
            handleFirst={handleFirst}
            handleLast={handleLast}
          />
        </div>
      ) : isLoading ? (
        <Loader />
      ) : reduxData.data?.length === 0 ? (
        <>
          {popUp && (
            <div ref={ref}>
              <PopUpFilter
                closePopUp={closePopUp}
                setTradeList={setTradeList}
              />
            </div>
          )}
          <div className="customFilterButton">
            <ul>
              <li onClick={togglePopUp}>
                Filters{" "}
                <span>
                  <img src={FilterIcon} alt="main filter" />
                </span>
              </li>
            </ul>
          </div>
          <div className="main-content demo-b">
            <div className="tradelog-tbl">
              <div className="table_wrapper">
                <div className="table-responsive">
                  <Table cellSpacing="0" cellPadding="0">
                    <thead>
                      <tr>
                        {sortedTableHeading.map((header, _index) => (
                          <th key={header}>
                            {header}
                            {sortable_TableHeadings.includes(header) && (
                              <span
                                className="sort-arrow"
                                onClick={() =>
                                  setSort((prev) => {
                                    if (
                                      prev?.label === "" ||
                                      prev?.label !== header
                                    ) {
                                      setSort((prev) => ({
                                        ...prev,
                                        label: header,
                                        sort: "ASC",
                                      }));
                                      sortDataBy(
                                        tradeList,
                                        tableHeadingObj[header]
                                      );
                                    } else if (
                                      prev?.label === header &&
                                      prev?.label === "ASC"
                                    ) {
                                      setSort((prev) => ({
                                        ...prev,
                                        label: header,
                                        sort: "DESC",
                                      }));
                                      sortDataBy(
                                        tradeList,
                                        tableHeadingObj[header]
                                      );
                                    } else if (
                                      prev?.label === header &&
                                      prev?.label === "DESC"
                                    ) {
                                      setSort((prev) => ({
                                        ...prev,
                                        label: header,
                                        sort: "ASC",
                                      }));
                                      sortDataBy(
                                        tradeList,
                                        tableHeadingObj[header]
                                      );
                                    }
                                  })
                                }
                              >
                                <img
                                  style={{ cursor: "pointer" }}
                                  src={
                                    sort?.label === header
                                      ? sort?.sort === "ASC"
                                        ? ArrowUP
                                        : DownArrow
                                      : DownArrow
                                  }
                                />
                              </span>
                            )}
                          </th>
                        ))}

                        <th>Daily Questionnaire</th>
                        {columnDetail?.length > 0 &&
                          columnDetail?.map((header, index) => (
                            <th key={index}>
                              {header?.column_name}
                              <button
                                className="add_button"
                                onClick={() => {
                                  deleteColumn(header?.id);
                                }}
                              >
                                Delete
                              </button>
                            </th>
                          ))}

                        <th>
                          {!edit && (
                            <div
                              className="add_button"
                              onMouseEnter={handleMouseEnter}
                              onMouseLeave={handleMouseLeave}
                            >
                              {plan === "Pro" || plan === "" ? (
                                "+"
                              ) : (
                                <img
                                  src={lock}
                                  height={20}
                                  width={20}
                                  alt="locked"
                                />
                              )}
                              {popupVisible && (
                                <div
                                  className={`popup ${
                                    popupVisible ? "popup-visible" : ""
                                  }`}
                                  style={{
                                    top: `${popupPosition.y}px`,
                                    left: `${popupPosition.x - 200}px`,
                                    padding: "10px",
                                  }}
                                >
                                  {plan === "Pro" || plan === "" ? (
                                    <>
                                      <div
                                        className="popup-header"
                                        style={{
                                          marginBottom: "5px",
                                          color: "black",
                                        }}
                                      >
                                        Add New Column
                                      </div>
                                      <input
                                        type="text"
                                        className="popupin"
                                        value={inputValue}
                                        onChange={handleInputChange}
                                        placeholder="New Column Name"
                                      />
                                      <button
                                        className="popupsav"
                                        onClick={() => {
                                          handleSave();
                                          handleAddColumn(inputValue);
                                        }}
                                      >
                                        Save
                                      </button>
                                    </>
                                  ) : (
                                    <div
                                      className="popup-header"
                                      style={{
                                        color: "black",
                                        padding: "10px",
                                      }}
                                    >
                                      Subscribe to Pro
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          )}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <>
                      <Formik
                        initialValues={{
                          asset_class: "",
                          position_size: null,
                          points_captured: null,
                          trade_pnl: null,
                          position: "",
                          buy_sell: "",
                          entry_price: null,
                          trade_karma: "",
                          trade_date: null,
                          holding_trade_type: "",
                          trade_charges: "",
                          trading_account: "",
                          stop_loss: null,
                          trade_target: null,
                          trade_conviction: "",
                          strategy_used: "",
                          trade_risk: "1:1",
                          reason_for_trade: "",
                          percentage_of_account_risked: "",
                          // image: "",
                          trade_slippage: null,
                          trade_penalties: "",
                          net_roi: null,
                          opening_balance: null,
                          comment: "",
                          ...columnDetail?.reduce((acc, item) => {
                            return {
                              ...acc,
                              [item.column_name]: "",
                            };
                          }, {}),
                          dynamicColumn: [],
                          questionnaire_answers: Array(8).fill(""),
                        }}
                        validationSchema={tradeSchema}
                        innerRef={(instance) => (formikRef.current = instance)}
                        // onSubmit={(values) => {
                        //   handleSaveSubmit(values, token);
                        // }}
                      >
                        {({ values, setFieldValue }) =>
                          !edit && (
                            <tr key={"first"} className="first">
                              <td ref={formRefs.trade_date}>
                                <ReactDatePicker
                                  id="trade_date"
                                  name="trade_date"
                                  closeOnScroll={true}
                                  placeholderText="Select Date"
                                  selected={startDate}
                                  onChange={(date) => {
                                    const dateWithCurrentTimeIST =
                                      setCurrentTimeInIST(date);
                                    setStartDate(dateWithCurrentTimeIST);
                                    setFieldValue(
                                      "trade_date",
                                      dateWithCurrentTimeIST
                                    );
                                  }}
                                  dateFormat="yyyy-MM-dd"
                                  maxDate={new Date()}
                                />
                                <ErrorMessage name="trade_date">
                                  {() => (
                                    <span
                                      style={{ color: "red", fontSize: "20px" }}
                                    >
                                      <b>*</b>
                                    </span>
                                  )}
                                </ErrorMessage>
                              </td>
                              <td>
                                <Field as="select" name="asset_class">
                                  <option value="" disabled>
                                    Select
                                  </option>
                                  <option value="Equity">Equity</option>
                                  <option value="Futures">Futures</option>
                                  <option value="Options">Options</option>
                                  <option value="Currency">Currency</option>
                                  <option value="Commodity">Commodity</option>
                                  <option value="Crypto">Crypto</option>
                                </Field>
                                <ErrorMessage
                                  name="asset_class"
                                  component="div"
                                />
                              </td>
                              <td ref={formRefs.position}>
                                <Field type="text" name="position" />
                                <ErrorMessage name="position">
                                  {() => (
                                    <span
                                      style={{ color: "red", fontSize: "20px" }}
                                    >
                                      <b>*</b>
                                    </span>
                                  )}
                                </ErrorMessage>
                              </td>
                              <td id="bu_se" ref={formRefs.buy_sell}>
                                <Field as="select" name="buy_sell">
                                  <option value="" disabled>
                                    Select
                                  </option>
                                  <option value="Buy">Buy</option>
                                  <option value="Sell">Sell</option>
                                </Field>
                                <ErrorMessage name="buy_sell">
                                  {() => (
                                    <div
                                      style={{ color: "red", fontSize: "20px" }}
                                    >
                                      <b>*</b>
                                    </div>
                                  )}
                                </ErrorMessage>
                              </td>
                              <td ref={formRefs.entry_price}>
                                <Field
                                  type="number"
                                  name="entry_price"
                                  min="0"
                                />
                                <ErrorMessage name="entry_price">
                                  {() => (
                                    <span
                                      style={{ color: "red", fontSize: "20px" }}
                                    >
                                      <b>*</b>
                                    </span>
                                  )}
                                </ErrorMessage>
                              </td>
                              <td ref={formRefs.position_size}>
                                <Field
                                  type="number"
                                  name="position_size"
                                  min="0"
                                />
                              </td>
                              <td>
                                <Field type="number" name="stop_loss" />
                              </td>
                              <td>
                                <Field type="number" name="trade_target" />
                              </td>
                              <td ref={formRefs.trade_pnl}>
                                <Field type="number" name="trade_pnl" />
                                <ErrorMessage name="trade_pnl">
                                  {() => (
                                    <span
                                      style={{ color: "red", fontSize: "20px" }}
                                    >
                                      <b>*</b>
                                    </span>
                                  )}
                                </ErrorMessage>
                              </td>
                              <td>
                                <Field type="number" name="points_captured" />
                              </td>
                              <td>
                                <Field type="number" name="net_roi" />
                              </td>
                              <td>
                                <Field as="select" name="strategy_used">
                                  <option>Select</option>
                                  {strategies?.length > 0 &&
                                    strategies.map((el, index) => (
                                      <option key={index}>
                                        {el.strategies_name}
                                      </option>
                                    ))}
                                </Field>
                                <ErrorMessage
                                  name="strategy_used"
                                  component="div"
                                />
                              </td>
                              <td>
                                <Field as="select" name="holding_trade_type">
                                  <option>Select</option>
                                  <option value="Positional">Positional</option>
                                  <option value="Intraday">Intraday</option>
                                  <option value="Swing">Swing</option>
                                  <option value="Short Term">Short Term</option>
                                  <option value="Long Term">Long Term</option>
                                  <option value="Expiry">Expiry</option>
                                  <option value="BTST">BTST</option>
                                </Field>
                                <ErrorMessage
                                  name="holding_trade_type"
                                  component="div"
                                />
                              </td>
                              <td>
                                <Field as="select" name="trade_conviction">
                                  <option>Select</option>
                                  <option value="Low">Low</option>
                                  <option value="Medium">Medium</option>
                                  <option value="High">High</option>
                                </Field>
                                <ErrorMessage
                                  name="trade_conviction"
                                  component="div"
                                />
                              </td>

                              <td>
                                <input
                                  className="w-[50%]"
                                  type="number"
                                  min="0"
                                  onChange={(e) => {
                                    setFieldValue(
                                      "trade_risk",
                                      `${e.target.value}:${
                                        values?.trade_risk
                                          ?.replace(/ /g, "")
                                          .split(/:/g)[1] || 1
                                      }`
                                    );
                                  }}
                                  value={
                                    values?.trade_risk
                                      ?.replace(/ /g, "")
                                      .split(/:/g)[0] || 1
                                  }
                                />
                                <span className="font-bold, mx-2 text-center text-2xl">
                                  :
                                </span>
                                <input
                                  className="w-[50%]"
                                  type="number"
                                  min="0"
                                  onChange={(e) => {
                                    setFieldValue(
                                      "trade_risk",
                                      `${
                                        values?.trade_risk
                                          ?.replace(/ /g, "")
                                          .split(/:/g)[0] || 1
                                      }:${e.target.value}`
                                    );
                                  }}
                                  value={
                                    values?.trade_risk
                                      ?.replace(/ /g, "")
                                      .split(/:/g)[1] || 1
                                  }
                                />
                              </td>
                              <td>
                                <Field type="text" name="reason_for_trade" />
                                <ErrorMessage
                                  name="reason_for_trade"
                                  component="div"
                                />
                              </td>
                              <td>
                                <Field as="select" name="trade_karma">
                                  <option>Select</option>
                                  <option value="Satisfied">Satisfied</option>
                                  <option value="Unsatisfied">
                                    Unsatisfied
                                  </option>
                                </Field>
                                <ErrorMessage
                                  name="trade_karma"
                                  component="div"
                                />
                              </td>
                              <td>
                                <Field type="text" name="comment" />
                                <ErrorMessage name="comment" component="div" />
                              </td>
                              <td ref={formRefs.percentage_of_account_risked}>
                                <Field
                                  type="number"
                                  name="percentage_of_account_risked"
                                  min="0"
                                />
                                <ErrorMessage
                                  name="percentage_of_account_risked"
                                  component="div"
                                />
                              </td>
                              <td ref={formRefs.trade_charges}>
                                <Field
                                  type="number"
                                  name="trade_charges"
                                  min="0"
                                />
                                <ErrorMessage name="trade_charges">
                                  {() => (
                                    <span
                                      style={{ color: "red", fontSize: "20px" }}
                                    >
                                      <b>*</b>
                                    </span>
                                  )}
                                </ErrorMessage>
                              </td>
                              <td ref={formRefs.trade_slippage}>
                                <Field type="number" name="trade_slippage" />
                              </td>
                              <td ref={formRefs.trade_penalties}>
                                <Field
                                  type="number"
                                  name="trade_penalties"
                                  min="0"
                                />
                                <ErrorMessage name="trade_penalties">
                                  {() => (
                                    <span
                                      style={{ color: "red", fontSize: "20px" }}
                                    >
                                      <b>*</b>
                                    </span>
                                  )}
                                </ErrorMessage>
                              </td>
                              <td>
                                <Field as="select" name="trading_account">
                                  <option>Select</option>
                                  {accountList.map((account) => (
                                    <option key={account.account_Id}>
                                      {account?.trading_account}
                                    </option>
                                  ))}
                                </Field>
                                <ErrorMessage
                                  name="trading_account"
                                  component="div"
                                />
                              </td>
                              <td>
                                <Field type="number" name="opening_balance" />
                                <ErrorMessage name="opening_balance">
                                  {() => (
                                    <span
                                      style={{ color: "red", fontSize: "20px" }}
                                    >
                                      <b>*</b>
                                    </span>
                                  )}
                                </ErrorMessage>
                              </td>
                              {/* <td className="special-col">
                                <input
                                  type="file"
                                  name="image"
                                  id="image"
                                  onChange={(e) => setImage(e.target.files[0])}
                                />
                                {showPrev && (
                                  <div className="img-preview">
                                    {image && (
                                      <img src={URL.createObjectURL(image)} />
                                    )}
                                  </div>
                                )}

                                <img
                                  src={eye}
                                  alt="Preview"
                                  height={21}
                                  className="prev_eye"
                                  width={21}
                                  onClick={() => setShowPrev((prev) => !prev)}
                                />
                                <ErrorMessage name="image" component="div" />
                              </td> */}

                              <td>
                                <button
                                  onClick={openQuestionnaire}
                                  type="button"
                                  className="fill_button"
                                >
                                  Fill up
                                </button>
                                {showQuestionnaire && !edit && (
                                  <DailyQuestionnaire
                                    onClose={closeQuestionnaire}
                                    onSubmitAnswers={(answers) =>
                                      handleQuestionnaireSubmit(
                                        answers,
                                        setFieldValue
                                      )
                                    }
                                  />
                                )}
                              </td>

                              {columnDetail?.length > 0 &&
                                columnDetail?.map(
                                  (items) =>
                                    items?.column_name && (
                                      <td>
                                        <Field
                                          type="text"
                                          name={`${items?.column_name}`}
                                        />
                                        <ErrorMessage
                                          name={`${items?.column_name}`}
                                          component="div"
                                        />
                                      </td>
                                    )
                                )}

                              <td>
                                <button
                                  type="submit"
                                  className="submit-btn"
                                  onClick={() => {
                                    handleSaveSubmit(values, token);
                                  }}
                                >
                                  Save
                                </button>
                              </td>
                            </tr>
                          )
                        }
                      </Formik>
                      </>
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              marginTop: "100px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={NoTradeData} alt="noTradeLogDataFound" />
          </div>
        </>
      ) : (
        <>
          <div className="customFilterButton">
            <ul>
              <button>Edit </button>
              <button>Filters </button>
            </ul>
          </div>
          <div>
            <i>Server Issue</i>
          </div>
        </>
      )}
    </>
  );
}

export default TradeLog;
