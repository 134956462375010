import React, { useState } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchUserByEmail,
  savePayment,
  updateUserPayment,
} from "../../store/slice/userSlice";
import "./Billing.css";
import axios from "axios";

const userAPIUrl = process.env.REACT_APP_AUTH_API_URL;

function Billing() {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(false);
  const { type, isYearly, amount } = location.state || {};
  const [payid, setPayid] = useState(null);
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };  

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    contact: "",
    address: "",
    pincode: "",
    city: "",
    state: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const savePayment = async (paymentdata) => {
    try {
      const response = await axios.post(
        `${userAPIUrl}/auth/user/add-payment`,
        paymentdata
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const numericValue = parseInt(amount.replace(/[^0-9]/g, ""));
  const gst = 0;
  const totalAmount = numericValue + gst;
  const totalrazor = totalAmount * 100;

  const handlePayment = async (e) => {
    e.preventDefault();
    for (const key in formData) {
      if (formData[key].trim() === "") {
        alert("Fill all details");
        return;
      }
    }
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(formData.email)) {
      alert("Please enter a valid email address");
      return;
    }
    const resp = await dispatch(fetchUserByEmail(formData.email));
    if (resp.payload.status === 401) {
      alert(
        "User with given Email Id not found. Please Signup first with your given Email Id."
      );
      return;
    }
    const user = resp.payload;
    var options = {
      key: "rzp_live_gTySGvD5vC4R2U",
      key_secret: "P4xjMaaJpp2gl11eQ7cWp485",
      amount: totalrazor, //totalrazor
      currency: "INR",
      name: "My Trade Journal",
      description: "Subscription",
      handler: function (response) {
        const paymentid = response.razorpay_payment_id;
        setPayid(paymentid);
        let paymentData;
        if (paymentid) {
          paymentData = {
            paymentId: paymentid,
            type: type,
            isYearly: isYearly,
            amount: totalAmount,
            ...formData,
          };
          const data = {
            token: user.auth_token,
            values: { type: type, isYearly: isYearly, userId: user.id },
          };
          dispatch(updateUserPayment(data));
          savePayment(paymentData);
          setIsPopupVisible(true);
        } else {
          alert("Payment not done");
          resetForm();
        }
      },
      theme: {
        color: "green",
      },
    };
    var pay = new window.Razorpay(options);
    pay.open();
  };

  const resetForm = () => {
    setFormData({
      firstName: "",
      lastName: "",
      email: "",
      contact: "",
      address: "",
      pincode: "",
      city: "",
      state: "",
    });
  };

  const handleLoginClick = () => {
    navigate("/login");
  };

  const handleOkClick = () => {
    navigate("/"); // Redirect to main page
  };

  return (
    <div className="test">
      <form style={{ width: "100%", display: "flex", flexDirection: "column" }}>
        <div style={{ margin: "30px 100px" }}>
          <div className="headi">
            {type} Plan / {isYearly ? "Year" : "Month"}
          </div>
        </div>
        <div className="boxes">
          <div className="info1">
            <div className="info1-headi">Billing Information</div>
            <div className="inputs1">
              <div className="names">
                <input
                  className="inp"
                  type="text"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleInputChange}
                  placeholder="First Name*"
                  required
                />
              </div>
              <div className="names">
                <input
                  className="inp"
                  type="text"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleInputChange}
                  placeholder="Last Name*"
                  required
                />
              </div>
            </div>
            <div className="inputs1">
              <div className="names">
                <input
                  className="inp"
                  type="text"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  placeholder="Email*"
                  required
                />
              </div>
              <div className="names">
                <input
                  className="inp"
                  type="text"
                  name="contact"
                  value={formData.contact}
                  onChange={handleInputChange}
                  placeholder="Contact"
                />
              </div>
            </div>
            <div className="inputs1">
              <div className="names2">
                <textarea
                  className="inp2"
                  type="text"
                  name="address"
                  value={formData.address}
                  onChange={handleInputChange}
                  placeholder="Address"
                />
              </div>
            </div>
            <div className="inputs1">
              <div className="names">
                <input
                  className="inp"
                  type="text"
                  name="pincode"
                  value={formData.pincode}
                  onChange={handleInputChange}
                  placeholder="Pincode*"
                  required
                />
              </div>
              <div className="names">
                <input
                  className="inp"
                  type="text"
                  name="city"
                  value={formData.city}
                  onChange={handleInputChange}
                  placeholder="City"
                />
              </div>
            </div>
            <div className="inputs1">
              <div className="names">
                <input
                  className="inp"
                  type="text"
                  name="state"
                  value={formData.state}
                  onChange={handleInputChange}
                  placeholder="State"
                />
              </div>
            </div>
          </div>
          <div className="info2">
            <div className="info2-headi">Payment Information</div>
            <div className="amounts">
              <div className="amount_type">Amount</div>
              <div>:</div>
              <div className="amount_num">₹{totalAmount}</div>
            </div>
            <div className="amounts" id="finall">
              <div className="amount_type">Payable Amount</div>
              <div>:</div>
              <div className="amount_num">₹{totalAmount}</div>
            </div>
            <div className="subb">
              *Prices inclusive of all applicable taxes
            </div>
            <div className="tc">
              <input type="checkbox" id="tandc" name="tandc" value="Terms" checked={isChecked}
                onChange={handleCheckboxChange}/>
              <label for="tandc">I agree to <Link to="/terms_and_conditions" target="_blank">Terms, Conditions</Link> & <Link to="/privacy_policy" target="_blank">Privacy policy</Link></label>
            </div>
            <div>
              <button
                className="pay_button"
                type="submit"
                onClick={handlePayment}
                disabled={!isChecked}
              >
                Confirm and Pay
              </button>
            </div>
          </div>
        </div>
      </form>
      {isPopupVisible && (
        <div className="popup-overlay">
          <div className="popup-content">
            <h2>Payment Successful!</h2>
            <p>
              Your payment ID is <strong>{payid}</strong>.
            </p>
            <div className="popup-buttons">
              <button className="popup-button" onClick={handleLoginClick}>
                Login
              </button>
              <button className="popup-button" onClick={handleOkClick}>
                Ok
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Billing;
