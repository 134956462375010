import React from 'react';
import './pricing.css';
import { useNavigate } from "react-router-dom";
import vectorimg from './Vector.svg';
import usericon from "../../assets/images/usericon.svg";
function Pricing() {

  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path); 
  };

  return (
    <section>
      <h2 className="heading1">Choose The Plan<br/> <span id="para2">That Is Right For You</span></h2>
      <div className="small-container">
        <div className="row pb-3">
          <div className="col-xl-5 col-lg-5" id="box-3">
            <div className="vector mt-3">
              <img src={vectorimg} alt="" />
              &nbsp;
              <h2 style={{ color: 'rgba(244, 244, 244, 1)', fontSize: '40px',textAlign:'center' }}>Start saving</h2>
              <h2 id="money" style={{textAlign:'center', fontSize: '40px'}}>your money</h2>
              <div className='px-3'>
                <h6 id="para3">
                Select the plan that best suits your needs and start saving today. <br/>Need more details? Feel free to reach out to us for assistance.
                </h6>
            </div>
              &nbsp;
              <div className="middle">
                <p>"Great tool for tracking and analyzing daily trades!”</p>
                <div className="ellipse">
                  <div style={{padding: "10px"}}><img src={usericon} alt="User" height={60} width={60}/></div>
                  <span><strong>Arjun Nair</strong><p>Freelancer</p></span>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-6 col-lg-6" id="box-4">
            <h1 className="start mt-5">Start Your 30-Day Free Trial</h1>
            <h2 id="get">No credit card required. Begin your journey now!</h2>
            <div className="row">
              <div className="col-xl-6 col-lg-6">
                <div className="xmcontainer">
                  <h2 style={{ textAlign: 'center', color: 'rgba(27, 30, 49, 1)', fontFamily: 'poppins', paddingTop: '20px' }}>Basic</h2>
                  <h6 style={{ textAlign: 'center', color: 'rgba(96, 97, 101, 1)' }}>Starting at</h6>
                  <h6 style={{color: "gray"}}><s>₹249</s></h6>
                  <div className="pricing_rates" style={{color: "gray"}}>
                    ₹199/Month
                  </div>
                  <hr className="divider2" style={{color: "black"}}/>
                  <div className="tick">
                    <p className="para-tick"><i className="bi bi-check-lg" style={{ fontSize: '20px' }}></i> Trade Logging and Tracking</p>
                    <p className="para-tick"><i className="bi bi-check-lg" style={{ fontSize: '20px' }}></i> Adding Trading Accounts & Strategies</p>
                    <p className="para-tick"><i className="bi bi-check-lg" style={{ fontSize: '20px' }}></i> Time-Specific Analysis</p>
                    <p className="para-tick"><i className="bi bi-check-lg" style={{ fontSize: '20px' }}></i> Equity Account Growth Curve</p>
                  </div>
                  <div className="text-center">
                    <button className="learn" onClick={() => handleNavigation("/pricing_ranges")}>Learn More <i className="bi bi-chevron-compact-right"></i></button>
                  </div>
                </div>
              </div>

              <div className="col-xl-6 col-lg-6">
                <div className="xmcontainer" id='xm22'>
                  <h2 style={{ textAlign: 'center', color: 'rgba(250, 185, 21, 1)', fontFamily: 'poppins', paddingTop: '20px' }}>Pro</h2>
                  <h6 style={{ textAlign: 'center', color: 'rgba(255, 255, 255, 1)' }}>Starting at</h6>
                  <h6 style={{color: "white"}}><s>₹449</s></h6>
                  <div className="pricing_rates" style={{color: "white"}}>
                    ₹349/Month
                  </div>
                  <hr className="divider3" style={{color: "white"}}/>
                  <div className="tick" id='t2'>
                    <p className="para-tick"><i className="bi bi-check-lg" style={{ fontSize: '20px' }}></i> Insights Based on Performance</p>
                    <p className="para-tick"><i className="bi bi-check-lg" style={{ fontSize: '20px' }}></i> Future Projection Graph</p>
                    <p className="para-tick"><i className="bi bi-check-lg" style={{ fontSize: '20px' }}></i> Exporting Trade Logs</p>
                    <p className="para-tick"><i className="bi bi-check-lg" style={{ fontSize: '20px' }}></i> Adding customised Trade log columns</p>
                  </div>
                  <div className="text-center">
                    <button className="learn" id='l2' onClick={() => handleNavigation("/pricing_ranges")}>Learn More <i className="bi bi-chevron-compact-right"></i></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Pricing;
