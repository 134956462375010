import React from "react";
import { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import { forwardRef } from "react";
import { Button } from "react-bootstrap";
import arrowDown from "./../../assets/images/arrowDown.svg";
import { useSelector, useDispatch } from "react-redux";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { faker } from "@faker-js/faker";
import NoData from "./../../assets/images/noData.svg";
import { tradeLogUpdateFilter } from "../../store/slice/tradeLogSlice";

const FutureSimulator = ({ isLocked }) => {
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [year, setYear] = useState();
  const [stockData, setStockData] = useState([]);
  const token = useSelector((state) => state.auth.token);
  const reduxData = useSelector((state) => state);
  const dispatch = useDispatch();
  const converter = (data) => {
    return data.join(",");
  };
  const asset = converter(reduxData.trades.filterData[0].selected);
  const conv = converter(reduxData.trades.filterData[2].selected);
  const holding = converter(reduxData.trades.filterData[1].selected);
  const tradeAcc = converter(reduxData.trades.filterData[3].selected);
  const strag = converter(reduxData.trades.filterData[4].selected);

  const ExampleCustomInput = forwardRef(
    ({ value, onClick, placeholder }, ref) => (
      <button className="customDateInput2" onClick={onClick} ref={ref}>
        {value ? value : placeholder}
        <span className="arrow-icon">
          <img src={arrowDown} alt="arrow-down" />
        </span>
      </button>
    )
  );
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    PointElement,
    LineElement
  );
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
      },
    },
  };
  const chartData = {
    labels: stockData.map((entry) => entry.date),
    datasets: [
      {
        label: "Stock Price",
        fill: false,
        backgroundColor: "rgba(75,192,192,0.4)",
        borderColor: "rgba(75,192,192,1)",
        data: stockData.map((entry) => entry.price),
      },
    ],
  };
  const generateRandomData = () => {
    const data = [];
    let currentDate = new Date(startDate);

    while (currentDate <= endDate) {
      const stockPrice = faker.finance.amount();
      data.push({
        date: currentDate.toISOString().split("T")[0],
        price: parseFloat(stockPrice),
      });

      currentDate.setDate(currentDate.getDate() + 1);
    }

    setStockData(data);
  };

  const resetDate = () => {
    setStartDate(null);
    setEndDate(null);
  };

  const isThreeMonthGap = () => {
    if (startDate && endDate) {
      const threeMonthsInMillis = 3 * 30 * 24 * 60 * 60 * 1000; // 3 months in milliseconds
      const timeDifference = endDate.getTime() - startDate.getTime();
      return timeDifference >= threeMonthsInMillis;
    }
    return false;
  };

  const formatDate = (date) => {
    // Use toISOString to get a standardized date string and extract yyyy-mm-dd
    return date.toISOString().split("T")[0];
  };

  function findNearestBoundaryTrade(trades, startDate, endDate) {
    const dataa = trades.data;
    let startingTrade = dataa[0];
    let endingTrade = dataa[dataa.length - 1];
    let minDistance = Infinity;
    let minDistance1 = Infinity;
    for (let i = 0; i < dataa.length; i++) {
      const trade = dataa[i];
      const distance = Math.abs(
        new Date(trade.trade_date).getTime() - startDate.getTime()
      );
      const distance1 = Math.abs(
        new Date(trade.trade_date).getTime() - endDate.getTime()
      );
      if (distance < minDistance) {
        minDistance = distance;
        startingTrade = trade;
      }
      if (distance1 < minDistance1) {
        minDistance1 = distance1;
        endingTrade = trade;
      }
    }
    return [startingTrade, endingTrade];
  }

  const calculateRate = () => {
    let [beginningTrade, endingTrade] = findNearestBoundaryTrade(
      reduxData.trades.data,
      startDate,
      endDate
    );
  
    const start = new Date(beginningTrade.trade_date);
    const end = new Date(endingTrade.trade_date);
    
    // Calculate the time difference
    const totalMonths =
      (end.getFullYear() - start.getFullYear()) * 12 +
      (end.getMonth() - start.getMonth());
  
    let n;
  
    // If the time difference is less than 12 months, use the number of months as n
    if (totalMonths < 12) {
      n = totalMonths;
    } else {
      // Otherwise, use the number of years as n
      n = totalMonths / 12; // Converting months to years
    }
  
    let CAGR = Math.pow(
      endingTrade.opening_balance / beginningTrade.opening_balance,
      1 / n
    );
  
    if (CAGR > 1) CAGR -= 1;
    return CAGR;
  };
  

  const calculateFutureValue = () => {
    let futureValue = 0;
    let investment = 0;
    let rate = calculateRate();
    let time = year;
    let [startingTrade, endingTrade] = findNearestBoundaryTrade(
      reduxData.trades.data,
      startDate,
      endDate
    );

    if (startingTrade && startingTrade.opening_balance) {
      investment = startingTrade.opening_balance;
    }
    futureValue = investment * Math.pow(1 + rate, time);
  };

  const extractTrades = () => {
    let makePayload = `?assetClass=${asset}&conviction=${conv}&strategyUsed=${strag}&minPnL=100&maxPnl=400&holdingTradeType=${holding}&tradingAccount=${tradeAcc}&startDate=${formatDate(
      startDate
    )}&endDate=${formatDate(endDate)}`;
    dispatch(
      tradeLogUpdateFilter({ token: token, values: makePayload })
    );
  };

  useEffect(() => {
    setStockData([]);
    if (isThreeMonthGap()) {
      extractTrades();
    }
  }, [startDate, endDate, year]);

  const onProject = () => {
    if (!isThreeMonthGap()) {
      alert("Minimum gap between start date and end date must be 3 months.");
      return;
    }
    generateRandomData();
    calculateFutureValue();
  };

  if (isLocked) {
    return (
      <div>
        <h2>Subscribe to Pro</h2>
        <p>Subscribe to Pro plan to use the Future Simulator</p>
      </div>
    );
  }

  return (
    <>
      <div className="future-simulator-header">
        <div className="fs-date-selector">
          <div className="ree2">
            <div className="customDateInput3">
              <select
                className="select-width"
                onChange={(e) => {
                  setYear(e.target.value);
                }}
              >
                <option>Select Year</option>
                <option value="1">1</option>
                <option value="3">3</option>
                <option value="5">5</option>
                <option value="10">10</option>
              </select>
            </div>
          </div>
          <div className="ree">
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              dateFormat="dd/MM/yyyy"
              customInput={<ExampleCustomInput />}
              placeholderText="Start Date"
              maxDate={new Date()}
            />
          </div>
          <div className="ree">
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              dateFormat="dd/MM/yyyy"
              customInput={<ExampleCustomInput />}
              placeholderText="End Date"
              maxDate={new Date()}
            />
          </div>
        </div>
        <div>
          <Button
            variant="primary"
            className="reset-button2"
            style={{ margin: "5px" }}
            onClick={onProject}
          >
            Project
          </Button>
          <Button
            variant="outline-primary"
            className="reset-button2"
            style={{ margin: "5px" }}
            onClick={resetDate}
          >
            Reset
          </Button>
        </div>
      </div>
      {stockData.length > 0 && isThreeMonthGap() ? (
        <div className="graph-container">
          <h2>Future Simulator</h2>
          <div>
            <Line options={options} data={chartData} height={"100%"} />
          </div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img src={NoData} alt="NodataImage" height="316.6px" width="442px" />
          <div style={{ width: "583px", height: "82px" }}>
            <p
              style={{
                textAlign: "center",
                fontSize: "26px",
                lineHeight: "41px",
                fontWeight: "500",
              }}
            >
              To use this feature, just make sure you have at least three months
              of data.
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default FutureSimulator;
