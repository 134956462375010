import React, { useEffect, useState } from "react";
import "./mantra.css";
import { useDispatch, useSelector } from "react-redux";
import { mantraList } from "../../store/slice/mantraSlice";
import InvertedComma from "./../../assets/images/invertedCommas.svg";
import MantrasBox from "./MantrasBox";
import MantraForm from "./MantraForm";

const Mantra = () => {
  const token = useSelector((state) => state.auth.token);
  const reduxData = useSelector((state) => state.mantras?.data);
  const dispatch = useDispatch();
  const [showForm, setShowForm] = useState(false);

  const closeForm = () => {
    setShowForm(false);
  };

  const showwForm = () => {
    setShowForm(true);
  };

  useEffect(() => {
    dispatch(mantraList(token));
  }, [dispatch, token]);

  const mantraData = [
    "The trick is not to learn to trust your gut feelings, but rather to discipline yourself to ignore them. Stand by your stocks as long as the fundamental story of the company hasn’t changed.",
    "The four most dangerous words in investing are: 'This time it's different.'",
    "In investing, what is comfortable is rarely profitable.",
    "The stock market is a device for transferring money from the impatient to the patient.",
    "Know what you own, and know why you own it.",
    "Do not be embarrassed by your failures, learn from them and start again.",
    "Successful investing is about managing risk, not avoiding it.",
  ];

  const [selectedMantra, setSelectedMantra] = useState(mantraData[0]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [fade, setFade] = useState(true);

  useEffect(() => {
    // Set up the interval to update the mantra every 10 seconds
    const interval = setInterval(() => {
      setFade(false); // Start fading out

      setTimeout(() => {
        // Calculate the next index in the sequence
        const nextIndex = (currentIndex + 1) % mantraData.length;
        setSelectedMantra(mantraData[nextIndex]);
        setCurrentIndex(nextIndex);
        setFade(true); // Fade in the new mantra
      }, 500); // Set a delay to wait for the fade-out transition to finish
    }, 10000); // Change every 10 seconds

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [currentIndex, mantraData]);

  return (
    <div>
      <div>
        <div className={`mantra-description ${fade ? "fade-in" : "fade-out"}`}>
          <div style={{ textAlign: "left" }}>
            <img src={InvertedComma} alt="inverted-comma"/>
          </div>
          <p className="mantra-text">{selectedMantra}</p>
        </div>
      </div>
      <div>{showForm && <MantraForm closeForm={closeForm} />}</div>
      <div>
        <MantrasBox mantras={reduxData} showForm={showwForm} />
      </div>
    </div>
  );
};

export default Mantra;
