import React, { useState } from 'react';
import axios from 'axios';
import './pass.css';

const authAPIUrl = process.env.REACT_APP_AUTH_API_URL; 

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post(`${authAPIUrl}/auth/forgot-password`, { email });
      setMessage(response.data);
    } catch (error) {
      setMessage('Failed to send password reset email. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <h2>Forgot Password</h2>
      <form id="fom" onSubmit={handleSubmit}>
        <div><label>
          Email:
          <input 
            type="email" 
            id='op'
            value={email} 
            onChange={(e) => setEmail(e.target.value)} 
            required 
          />
        </label></div>
        <button type="submit" disabled={loading} className='reb'>
          {loading ? 'Sending...' : 'Reset'}
        </button>
      </form>
      {message && <p style={{ margin: "10px" }}><i>{message}</i></p>}
    </div>
  );
};

export default ForgotPassword;
