import React from "react";
import "./service.css";
import icon2 from "./Icon (1) (1).svg";
import icon1 from "./Icon (2).svg";
import pc1 from "./pc (1).png";
import icon3 from "./Icon (2) (1).svg";
import icon4 from "./Icon (3).svg";
import gifFile from "./video.gif";

function Service() {
  return (
    <section className="services_ pt-4">
      <div id="mmmmm" style={{ padding: "30px" }}>
        <h2 className="text2">
          Why Choose <span id="gradient-text">My Trade Journal?</span>
        </h2>
        <div className="line">
          <span className="divider"></span>
        </div>
        <div className="container-fluid">
          <div className="row mb-3">
            <div className="img-res">
              <img
                src={icon1}
                id="iconimg1-res"
                className="iconimg"
                alt="Icon 1"
              />
            </div>
            <div
              className="transitionnnnnn col-lg-4 col-12"
              style={{ textAlign: "end", fontSize: "18px" }}
            >
              <h5 className="head">Comprehensive Insights</h5>
              Gain unparalleled insights into your trading patterns with our
              advanced analytics. Our detailed reports and charts help you
              understand your performance, so you can tweak your strategies for
              better results.
            </div>
            <div className="col-lg-4 col-12 alignimg" id="alignimg">
              <img src={icon1} id="iconimg" className="iconimg" alt="Icon 1" />
              <img src={icon2} id="iconimg2" className="iconimg" alt="Icon 2" />
            </div>
            <div className="img-res">
              <img
                src={icon2}
                id="iconimg2-res"
                className="iconimg"
                alt="Icon 2"
              />
            </div>

            <div
              className="transitionnnnnn col-lg-4 col-12"
              style={{ fontSize: "18px", textAlign: "left" }}
            >
              <h5
                style={{
                  fontFamily: "'Poppins'",
                  fontWeight: 700,
                  textAlign: "left",
                }}
              >
                Effortless Setup
              </h5>
              Ready to transform your trading game? Our user-friendly interface
              makes it easy to get started with minimal setup. No steep learning
              curves—just straightforward tools that let you dive right into
              tracking and analyzing your trades.
            </div>
          </div>

          <div className="row">
            <div className="col-lg-4 col-12"></div>

            <div className="col-lg-4 col-12 alignimg" id="alignimg-pc">
              <div className="position-relative">
                <img src={pc1} id="pcimg" alt="PC" />
                <img
                  src={gifFile}
                  alt="GIF"
                  className="gif-player"
                  style={{
                    width: "100%",
                    position: "absolute",
                    top: 0,
                    left: 0,
                  }}
                />
              </div>
            </div>

            <div className="col-lg-4 col-12"></div>
          </div>

          <div className="row mt-3">
            <div className="img-res">
              <img
                src={icon3}
                id="iconimg3-res"
                className="iconimg"
                alt="Icon 3"
              />
            </div>
            <div
              className="transitionnnnnn col-lg-4 col-12"
              style={{ textAlign: "end", fontSize: "18px" }}
            >
              <h5 className="head">Flexible and Affordable Plans</h5>
              We believe in providing value at every level. Our pricing plans
              are designed to be budget-friendly while offering all the features
              you need to enhance your trading. Choose the plan that fits your
              needs and start optimizing your trading strategy today.
            </div>
            <div className="col-lg-4 col-12 alignimg" id="alignimg2">
              <img src={icon3} id="iconimg3" className="iconimg" alt="Icon 3" />
              <img src={icon4} id="iconimg4" className="iconimg" alt="Icon 4" />
            </div>
            <div className="img-res">
              <img
                src={icon4}
                id="iconimg4-res"
                className="iconimg"
                alt="Icon 4"
              />
            </div>
            <div
              className="transitionnnnnn col-lg-4 col-12"
              style={{ fontSize: "18px", textAlign: "left" }}
            >
              <h5
                style={{
                  fontFamily: "'Poppins'",
                  fontWeight: 700,
                  textAlign: "left",
                }}
              >
                Customizable Experience
              </h5>
              Tailor the app to suit your unique trading style. From
              personalized dashboards to custom trade tracking categories, My
              Trade Journal adapts to your needs, providing a personalized
              experience that matches your trading approach.
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Service;
