import { useFormik } from "formik";
import React from "react";
import { strategyAdd, strategyEdit } from "../../store/slice/strategySlice";
import { useDispatch, useSelector } from "react-redux";

const StrategyForm = ({ setFormStatus, currentAccount }) => {
  const token = useSelector((state) => state?.auth?.token);
  const rD = useSelector((state) => state?.strategy?.data);
  const dispatch = useDispatch();

  // Formik form with validation
  const formik = useFormik({
    initialValues: {
      strategies_name: currentAccount?.strategies_name || "",
      strategies_desc: currentAccount?.strategies_desc || "",
    },
    enableReinitialize: true,
    
    // Validation function
    validate: (values) => {
      const errors = {};
      
      // Check if strategy name is empty
      if (!values.strategies_name.trim()) {
        errors.strategies_name = "Name must not be empty";
      }
      
      return errors;
    },
    
    onSubmit: (values) => {
      // Check if strategy with the same name already exists
      const strategyExists = rD.some(
        (strategy) => strategy.strategies_name === values.strategies_name
      );

      if (strategyExists) {
        alert("Strategy with this name already exists!");
        return; // Exit early if strategy already exists
      }

      // Proceed to add or edit strategy
      if (currentAccount) {
        dispatch(strategyEdit({ id: currentAccount?.id, values, token }));
      } else {
        dispatch(strategyAdd({ values, token }));
      }

      setFormStatus("list");
    },
  });

  return (
    <div className="strategy-box">
      <form onSubmit={formik.handleSubmit} style={{display: "block"}}>
        <label htmlFor="strategies_name">Strategy Name</label>
        <input
          id="strategies_name"
          name="strategies_name"
          value={formik.values.strategies_name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          placeholder="Strategy Name"
        />
        {formik.errors.strategies_name && formik.touched.strategies_name ? (
          <div style={{ color: 'red', fontSize: "12px" }}>{formik.errors.strategies_name}</div>
        ) : null}
        <br/>
        <label htmlFor="strategies_desc">Description</label>
        <textarea
          id="strategies_desc"
          name="strategies_desc"
          value={formik.values.strategies_desc}
          onChange={formik.handleChange}
          placeholder="Write your New Strategy details..."
        />
        
        <div className="btn-box">
          <button
            className="cancel"
            type="button"
            onClick={() => {
              formik.handleReset();
              setFormStatus("list");
            }}
          >
            Cancel
          </button>
          <button className="save" type="submit" disabled={formik.isSubmitting}>
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

export default StrategyForm;
